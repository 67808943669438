define("@ember-data/model/-private", ["exports", "@ember-data/model/index-8a7de041", "@ember/application"], function (_exports, _index8a7de, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Errors", {
    enumerable: true,
    get: function () {
      return _index8a7de.E;
    }
  });
  Object.defineProperty(_exports, "LEGACY_SUPPORT", {
    enumerable: true,
    get: function () {
      return _index8a7de.L;
    }
  });
  Object.defineProperty(_exports, "ManyArray", {
    enumerable: true,
    get: function () {
      return _index8a7de.R;
    }
  });
  Object.defineProperty(_exports, "Model", {
    enumerable: true,
    get: function () {
      return _index8a7de.M;
    }
  });
  Object.defineProperty(_exports, "PromiseBelongsTo", {
    enumerable: true,
    get: function () {
      return _index8a7de.P;
    }
  });
  Object.defineProperty(_exports, "PromiseManyArray", {
    enumerable: true,
    get: function () {
      return _index8a7de.c;
    }
  });
  _exports._modelForMixin = modelForMixin;
  Object.defineProperty(_exports, "attr", {
    enumerable: true,
    get: function () {
      return _index8a7de.a;
    }
  });
  Object.defineProperty(_exports, "belongsTo", {
    enumerable: true,
    get: function () {
      return _index8a7de.b;
    }
  });
  Object.defineProperty(_exports, "hasMany", {
    enumerable: true,
    get: function () {
      return _index8a7de.h;
    }
  });
  /*
      In case someone defined a relationship to a mixin, for example:
      ```
        import Model, { belongsTo, hasMany } from '@ember-data/model';
        import Mixin from '@ember/object/mixin';
  
        class CommentModel extends Model {
          @belongsTo('commentable', { polymorphic: true }) owner;
        }
  
        let Commentable = Mixin.create({
          @hasMany('comment') comments;
        });
      ```
      we want to look up a Commentable class which has all the necessary
      relationship meta data. Thus, we look up the mixin and create a mock
      Model, so we can access the relationship CPs of the mixin (`comments`)
      in this case
    */
  function modelForMixin(store, normalizedModelName) {
    let owner = (0, _application.getOwner)(store);
    let MaybeMixin = owner.factoryFor(`mixin:${normalizedModelName}`);
    let mixin = MaybeMixin && MaybeMixin.class;
    if (mixin) {
      let ModelForMixin = _index8a7de.M.extend(mixin);
      ModelForMixin.__isMixin = true;
      ModelForMixin.__mixin = mixin;
      //Cache the class as a model
      owner.register('model:' + normalizedModelName, ModelForMixin);
    }
    return owner.factoryFor(`model:${normalizedModelName}`);
  }
});