define('@fortawesome/pro-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  const faTrashCan = {
    prefix: 'far',
    iconName: 'trash-can',
    icon: [448, 512, [61460, "trash-alt"], "f2ed", "M170.5 51.6L151.5 80l145 0-19-28.4c-1.5-2.2-4-3.6-6.7-3.6l-93.7 0c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80 368 80l48 0 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 304c0 44.2-35.8 80-80 80l-224 0c-44.2 0-80-35.8-80-80l0-304-8 0c-13.3 0-24-10.7-24-24S10.7 80 24 80l8 0 48 0 13.8 0 36.7-55.1C140.9 9.4 158.4 0 177.1 0l93.7 0c18.7 0 36.2 9.4 46.6 24.9zM80 128l0 304c0 17.7 14.3 32 32 32l224 0c17.7 0 32-14.3 32-32l0-304L80 128zm80 64l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0l0 208c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-208c0-8.8 7.2-16 16-16s16 7.2 16 16z"]
  };
  const faFileLines = {
    prefix: 'far',
    iconName: 'file-lines',
    icon: [384, 512, [128441, 128462, 61686, "file-alt", "file-text"], "f15c", "M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm56 256c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z"]
  };
  const faDiceThree = {
    prefix: 'far',
    iconName: 'dice-three',
    icon: [448, 512, [9858], "f527", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm128 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm96 96a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm64 128a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  const faCalendarDays = {
    prefix: 'far',
    iconName: 'calendar-days',
    icon: [448, 512, ["calendar-alt"], "f073", "M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l80 0 0 56-80 0 0-56zm0 104l80 0 0 64-80 0 0-64zm128 0l96 0 0 64-96 0 0-64zm144 0l80 0 0 64-80 0 0-64zm80-48l-80 0 0-56 80 0 0 56zm0 160l0 40c0 8.8-7.2 16-16 16l-64 0 0-56 80 0zm-128 0l0 56-96 0 0-56 96 0zm-144 0l0 56-64 0c-8.8 0-16-7.2-16-16l0-40 80 0zM272 248l-96 0 0-56 96 0 0 56z"]
  };
  const faMp3Player = {
    prefix: 'far',
    iconName: 'mp3-player',
    icon: [384, 512, [], "f8ce", "M320 48c8.8 0 16 7.2 16 16l0 384c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l256 0zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L64 0zM192 304a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm0 128a96 96 0 1 0 0-192 96 96 0 1 0 0 192zM112 80c-17.7 0-32 14.3-32 32l0 48c0 17.7 14.3 32 32 32l160 0c17.7 0 32-14.3 32-32l0-48c0-17.7-14.3-32-32-32L112 80z"]
  };
  const faAnglesDown = {
    prefix: 'far',
    iconName: 'angles-down',
    icon: [448, 512, ["angle-double-down"], "f103", "M401 113L241 273c-9.4 9.4-24.6 9.4-33.9 0L47 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 79c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9zm0 192L241 465c-9.4 9.4-24.6 9.4-33.9 0L47 305c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 271c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
  };
  const faChevronsLeft = {
    prefix: 'far',
    iconName: 'chevrons-left',
    icon: [512, 512, ["chevron-double-left"], "f323", "M47 239c-9.4 9.4-9.4 24.6 0 33.9L239 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 273 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239zM431 47L239 239c-9.4 9.4-9.4 24.6 0 33.9L431 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-175-175L465 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0z"]
  };
  const faEnvelopesBulk = {
    prefix: 'far',
    iconName: 'envelopes-bulk',
    icon: [640, 512, ["mail-bulk"], "f674", "M112 48l320 0 0 48 48 0 0-48c0-26.5-21.5-48-48-48L112 0C85.5 0 64 21.5 64 48l0 144 48 0 0-144zM48 272l288 0 0 6.9L192.1 385.4c0 0-.1 0-.1 0s-.1 0-.1 0L48 278.9l0-6.9zm0 66.6L163.3 424c8.3 6.2 18.4 9.5 28.7 9.5s20.4-3.3 28.7-9.5L336 338.6 336 464 48 464l0-125.4zM0 272L0 464c0 26.5 21.5 48 48 48l288 0c26.5 0 48-21.5 48-48l0-192c0-26.5-21.5-48-48-48L48 224c-26.5 0-48 21.5-48 48zm272-80l0-16 320 0 0 192-176 0 0 48 176 0c26.5 0 48-21.5 48-48l0-192c0-26.5-21.5-48-48-48l-320 0c-26.5 0-48 21.5-48 48l0 16 48 0zm240 16c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0z"]
  };
  const faAddressBook = {
    prefix: 'far',
    iconName: 'address-book',
    icon: [512, 512, [62138, "contact-book"], "f2b9", "M384 48c8.8 0 16 7.2 16 16l0 384c0 8.8-7.2 16-16 16L96 464c-8.8 0-16-7.2-16-16L80 64c0-8.8 7.2-16 16-16l288 0zM96 0C60.7 0 32 28.7 32 64l0 384c0 35.3 28.7 64 64 64l288 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L96 0zM240 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80l-64 0zM512 80c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64zM496 192c-8.8 0-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64c0-8.8-7.2-16-16-16zm16 144c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64z"]
  };
  const faCaretRight = {
    prefix: 'far',
    iconName: 'caret-right',
    icon: [256, 512, [], "f0da", "M201.4 256L112 166.6l0 178.7L201.4 256zm45.3-22.6c12.5 12.5 12.5 32.8 0 45.3l-128 128c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6l0-256c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l128 128z"]
  };
  const faCircleNotch = {
    prefix: 'far',
    iconName: 'circle-notch',
    icon: [512, 512, [], "f1ce", "M215.1 26.3c3.6 12.7-3.7 26-16.5 29.7C111.6 80.9 48 161.1 48 256c0 114.9 93.1 208 208 208s208-93.1 208-208c0-94.9-63.6-175.1-150.6-200c-12.7-3.6-20.1-16.9-16.5-29.7s16.9-20.1 29.7-16.5C433.6 40.5 512 139.1 512 256c0 141.4-114.6 256-256 256S0 397.4 0 256C0 139.1 78.4 40.5 185.4 9.9c12.7-3.6 26 3.7 29.7 16.5z"]
  };
  const faTabletScreenButton = {
    prefix: 'far',
    iconName: 'tablet-screen-button',
    icon: [448, 512, ["tablet-alt"], "f3fa", "M48 448c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-80L48 368l0 80zm0-128l352 0 0-256c0-8.8-7.2-16-16-16L64 48c-8.8 0-16 7.2-16 16l0 256zM0 64C0 28.7 28.7 0 64 0L384 0c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM224 392a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"]
  };
  const faFilm = {
    prefix: 'far',
    iconName: 'film',
    icon: [512, 512, [127902], "f008", "M352 432l-192 0 0-112 0-40 192 0 0 40 0 112zm0-200l-192 0 0-40 0-112 192 0 0 112 0 40zM64 80l48 0 0 88-64 0 0-72c0-8.8 7.2-16 16-16zM48 216l64 0 0 80-64 0 0-80zm64 216l-48 0c-8.8 0-16-7.2-16-16l0-72 64 0 0 88zM400 168l0-88 48 0c8.8 0 16 7.2 16 16l0 72-64 0zm0 48l64 0 0 80-64 0 0-80zm0 128l64 0 0 72c0 8.8-7.2 16-16 16l-48 0 0-88zM448 32L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64z"]
  };
  const faCassetteTape = {
    prefix: 'far',
    iconName: 'cassette-tape',
    icon: [576, 512, [128429], "f8ab", "M512 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16l-40 0-25.6-61.5c-8.7-20.9-29.1-34.5-51.7-34.5l-213.3 0c-22.6 0-43 13.6-51.7 34.5L104 432l-40 0c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l448 0zM420 432l-264 0 17.9-43.1c1.2-3 4.2-4.9 7.4-4.9l213.3 0c3.2 0 6.1 1.9 7.4 4.9L420 432zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM176 208a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm64 16c0-5.5-.7-10.9-2-16l100 0c-1.3 5.1-2 10.5-2 16s.7 10.9 2 16l-100 0c1.3-5.1 2-10.5 2-16zm224 0c0-35.3-28.7-64-64-64l-224 0c-35.3 0-64 28.7-64 64s28.7 64 64 64l224 0c35.3 0 64-28.7 64-64zm-80 0a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  const faCaretLeft = {
    prefix: 'far',
    iconName: 'caret-left',
    icon: [256, 512, [], "f0d9", "M54.6 256L144 166.6l0 178.7L54.6 256zM9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l128 128c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6l0-256c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-128 128z"]
  };
  const faCircleExclamation = {
    prefix: 'far',
    iconName: 'circle-exclamation',
    icon: [512, 512, ["exclamation-circle"], "f06a", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
  };
  const faBoxTaped = {
    prefix: 'far',
    iconName: 'box-taped',
    icon: [448, 512, ["box-alt"], "f49a", "M261.3 80l81.1 0c6.3 0 12.1 3.7 14.6 9.5L388.4 160 288 160 261.3 80zm-74.7 0L160 160 59.6 160 91 89.5c2.6-5.8 8.3-9.5 14.6-9.5l81.1 0zM160 208l0 48c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32l0-48 112 0 0 208c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-208 112 0zM400.9 70c-10.3-23.1-33.2-38-58.5-38L105.6 32C80.3 32 57.4 46.9 47.1 70L5.5 163.6c-3.6 8.2-5.5 17-5.5 26L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-226.4c0-9-1.9-17.8-5.5-26L400.9 70z"]
  };
  const faSquareCheck = {
    prefix: 'far',
    iconName: 'square-check',
    icon: [448, 512, [9745, 9989, 61510, "check-square"], "f14a", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"]
  };
  const faLock = {
    prefix: 'far',
    iconName: 'lock',
    icon: [448, 512, [128274], "f023", "M144 128l0 64 160 0 0-64c0-44.2-35.8-80-80-80s-80 35.8-80 80zM96 192l0-64C96 57.3 153.3 0 224 0s128 57.3 128 128l0 64 32 0c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 256c0-35.3 28.7-64 64-64l32 0zM48 256l0 192c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-192c0-8.8-7.2-16-16-16L64 240c-8.8 0-16 7.2-16 16z"]
  };
  const faMapLocation = {
    prefix: 'far',
    iconName: 'map-location',
    icon: [576, 512, ["map-marked"], "f59f", "M403 148.6c3.2-10.3 5-20 5-28.6C408 53.7 354.3 0 288 0C226.4 0 175.6 46.4 168.8 106.2c-.5 4.5-.8 9.1-.8 13.8c0 10.3 2.6 22 7 34.6c.7 2.1 1.5 4.2 2.3 6.3c10.4 27 28.3 57.1 46.7 84.4c18.1 26.9 36.7 51.1 49.2 66.6c7.7 9.6 22 9.6 29.6 0c12.4-15.5 31.1-39.7 49.2-66.6c.1-.2 .3-.4 .4-.6c4.6-6.8 9.1-13.7 13.5-20.8c3.3-5.3 6.5-10.7 9.6-16l.1-.2c11.8-20.4 21.8-40.8 27.4-59.2zm-8.3 89.6c-13.9 22.5-29.1 44.2-42.7 62.4l0 153.1L224 407.2l0-106.6c-13.5-18.2-28.8-39.9-42.7-62.4c-1.8-2.9-3.6-5.8-5.3-8.8l0 177.7L48 453.7l0-252.9 96.9-35.2c-5.2-14.9-8.9-30.5-8.9-45.6c0-.8 0-1.5 0-2.3L15.8 161.4C6.3 164.9 0 173.9 0 184L0 488c0 7.8 3.8 15.2 10.2 19.7s14.6 5.6 22 2.9l167.8-61 167.8 61c5.3 1.9 11.1 1.9 16.4 0l176-64c9.5-3.4 15.8-12.5 15.8-22.6l0-304c0-7.8-3.8-15.2-10.2-19.7s-14.6-5.6-22-2.9l-105 38.2c-2.2 15-7.3 30.2-13.2 44.2c-2 4.6-4.1 9.3-6.3 14L528 154.3l0 252.9L400 453.7l0-224.3c-1.8 3-3.5 5.9-5.3 8.8z"]
  };
  const faShareNodes = {
    prefix: 'far',
    iconName: 'share-nodes',
    icon: [448, 512, ["share-alt"], "f1e0", "M448 128c0 53-43 96-96 96c-28.9 0-54.8-12.8-72.4-33l-89.7 44.9c1.4 6.5 2.1 13.2 2.1 20.1s-.7 13.6-2.1 20.1L279.6 321c17.6-20.2 43.5-33 72.4-33c53 0 96 43 96 96s-43 96-96 96s-96-43-96-96c0-6.9 .7-13.6 2.1-20.1L168.4 319c-17.6 20.2-43.5 33-72.4 33c-53 0-96-43-96-96s43-96 96-96c28.9 0 54.8 12.8 72.4 33l89.7-44.9c-1.4-6.5-2.1-13.2-2.1-20.1c0-53 43-96 96-96s96 43 96 96zM96 304a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM400 128a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM352 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96z"]
  };
  const faBagShopping = {
    prefix: 'far',
    iconName: 'bag-shopping',
    icon: [448, 512, ["shopping-bag"], "f290", "M160 112l0 48 128 0 0-48c0-35.3-28.7-64-64-64s-64 28.7-64 64zm-48 96l-64 0 0 208c0 26.5 21.5 48 48 48l256 0c26.5 0 48-21.5 48-48l0-208-64 0 0 56c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-56-128 0 0 56c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-56zm0-48l0-48C112 50.1 162.1 0 224 0s112 50.1 112 112l0 48 64 0c26.5 0 48 21.5 48 48l0 208c0 53-43 96-96 96L96 512c-53 0-96-43-96-96L0 208c0-26.5 21.5-48 48-48l64 0z"]
  };
  const faUsers = {
    prefix: 'far',
    iconName: 'users',
    icon: [640, 512, [], "f0c0", "M144 160A80 80 0 1 0 144 0a80 80 0 1 0 0 160zm368 0A80 80 0 1 0 512 0a80 80 0 1 0 0 160zM0 298.7C0 310.4 9.6 320 21.3 320l213.3 0c.2 0 .4 0 .7 0c-26.6-23.5-43.3-57.8-43.3-96c0-7.6 .7-15 1.9-22.3c-13.6-6.3-28.7-9.7-44.6-9.7l-42.7 0C47.8 192 0 239.8 0 298.7zM405.3 320l213.3 0c11.8 0 21.3-9.6 21.3-21.3C640 239.8 592.2 192 533.3 192l-42.7 0c-15.9 0-31 3.5-44.6 9.7c1.3 7.2 1.9 14.7 1.9 22.3c0 38.2-16.8 72.5-43.3 96c.2 0 .4 0 .7 0zM320 176a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm0 144a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm-58.7 80l117.3 0c39.8 0 73.2 27.2 82.6 64l-282.6 0c9.5-36.8 42.9-64 82.6-64zm0-48C187.7 352 128 411.7 128 485.3c0 14.7 11.9 26.7 26.7 26.7l330.7 0c14.7 0 26.7-11.9 26.7-26.7C512 411.7 452.3 352 378.7 352l-117.3 0z"]
  };
  const faChevronUp = {
    prefix: 'far',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M239 111c9.4-9.4 24.6-9.4 33.9 0L465 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-175-175L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 111z"]
  };
  const faBookmark = {
    prefix: 'far',
    iconName: 'bookmark',
    icon: [384, 512, [128278, 61591], "f02e", "M0 48C0 21.5 21.5 0 48 0l0 48 0 393.4 130.1-92.9c8.3-6 19.6-6 27.9 0L336 441.4 336 48 48 48 48 0 336 0c26.5 0 48 21.5 48 48l0 440c0 9-5 17.2-13 21.3s-17.6 3.4-24.9-1.8L192 397.5 37.9 507.5c-7.3 5.2-16.9 5.9-24.9 1.8S0 497 0 488L0 48z"]
  };
  const faCreditCardFront = {
    prefix: 'far',
    iconName: 'credit-card-front',
    icon: [576, 512, [], "f38a", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l448 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM96 360c0-13.3 10.7-24 24-24l48 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-48 0c-13.3 0-24-10.7-24-24zm128 0c0-13.3 10.7-24 24-24l112 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-112 0c-13.3 0-24-10.7-24-24zM96 280c0-13.3 10.7-24 24-24l336 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-336 0c-13.3 0-24-10.7-24-24zM376 128l80 0c13.3 0 24 10.7 24 24l0 48c0 13.3-10.7 24-24 24l-80 0c-13.3 0-24-10.7-24-24l0-48c0-13.3 10.7-24 24-24z"]
  };
  const faMug = {
    prefix: 'far',
    iconName: 'mug',
    icon: [576, 512, [], "f874", "M80 352c0 26.5 21.5 48 48 48l192 0c26.5 0 48-21.5 48-48l0-240L80 112l0 240zM32 96c0-17.7 14.3-32 32-32l320 0 64 0c70.7 0 128 57.3 128 128s-57.3 128-128 128l-32 0 0 32c0 53-43 96-96 96l-192 0c-53 0-96-43-96-96L32 96zM416 272l32 0c44.2 0 80-35.8 80-80s-35.8-80-80-80l-32 0 0 160z"]
  };
  const faUser = {
    prefix: 'far',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464l349.5 0c-8.9-63.3-63.3-112-129-112l-91.4 0c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3z"]
  };
  const faHighlighter = {
    prefix: 'far',
    iconName: 'highlighter',
    icon: [576, 512, [], "f591", "M184 296.6l0-48 22.5-16.2 113 113L303.4 368l-48 0c-12.7 0-24.9 5.1-33.9 14.1l-38.1 38.1-51.5-51.5 38.1-38.1c9-9 14.1-21.2 14.1-33.9zM502.8 90.2L347.9 306 246 204.1 461.8 49.2c1.1-.8 2.4-1.2 3.7-1.2c1.7 0 3.3 .7 4.5 1.9L502.1 82c1.2 1.2 1.9 2.8 1.9 4.5c0 1.3-.4 2.6-1.2 3.7zM255.4 416l48 0c15.5 0 30-7.4 39-20L541.8 118.2C548.4 109 552 97.9 552 86.5c0-14.4-5.7-28.2-15.9-38.4L503.9 15.9C493.7 5.7 479.9 0 465.5 0C454.1 0 443 3.6 433.8 10.2L156 209.6c-12.6 9-20 23.5-20 39l0 48L92.3 340.3c-10.7 10.7-14.1 26-10.1 39.5L9.4 452.7c-6 6-9.4 14.1-9.4 22.6L0 480c0 17.7 14.3 32 32 32l84.7 0c8.5 0 16.6-3.4 22.6-9.4l32.8-32.8c13.6 4 28.8 .6 39.5-10.1L255.4 416zM134.7 439.3L110.1 464l-44.1 0 46.7-46.7 22.1 22.1z"]
  };
  const faBoxCheck = {
    prefix: 'far',
    iconName: 'box-check',
    icon: [448, 512, [], "f467", "M248 80l94.4 0c6.3 0 12.1 3.7 14.6 9.5L388.4 160 248 160l0-80zM48 208l352 0 0 208c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-208zm152-48L59.6 160 91 89.5c2.6-5.8 8.3-9.5 14.6-9.5L200 80l0 80zM400.9 70c-10.3-23.1-33.2-38-58.5-38L105.6 32C80.3 32 57.4 46.9 47.1 70L5.5 163.6c-3.6 8.2-5.5 17-5.5 26L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-226.4c0-9-1.9-17.8-5.5-26L400.9 70zM337 265c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L337 265z"]
  };
  const faBan = {
    prefix: 'far',
    iconName: 'ban',
    icon: [512, 512, [128683, "cancel"], "f05e", "M385.1 419.1L92.9 126.9C64.8 162.3 48 207.2 48 256c0 114.9 93.1 208 208 208c48.8 0 93.7-16.8 129.1-44.9zm33.9-33.9C447.2 349.7 464 304.8 464 256c0-114.9-93.1-208-208-208c-48.8 0-93.7 16.8-129.1 44.9L419.1 385.1zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
  };
  const faStar = {
    prefix: 'far',
    iconName: 'star',
    icon: [576, 512, [11088, 61446], "f005", "M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"]
  };
  const faShapes = {
    prefix: 'far',
    iconName: 'shapes',
    icon: [512, 512, ["triangle-circle-square"], "f61f", "M288 63.1L220.3 176l135.5 0L288 63.1zM411.4 175.5c5.9 9.9 6.1 22.2 .4 32.2s-16.3 16.2-27.8 16.2l-192 0c-11.5 0-22.2-6.2-27.8-16.2s-5.5-22.3 .4-32.2l96-160C266.3 5.9 276.8 0 288 0s21.7 5.9 27.4 15.5l96 160zM464 320l-128 0 0 128 128 0 0-128zM328 272l144 0c22.1 0 40 17.9 40 40l0 144c0 22.1-17.9 40-40 40l-144 0c-22.1 0-40-17.9-40-40l0-144c0-22.1 17.9-40 40-40zM208 384A80 80 0 1 0 48 384a80 80 0 1 0 160 0zM0 384a128 128 0 1 1 256 0A128 128 0 1 1 0 384z"]
  };
  const faCrown = {
    prefix: 'far',
    iconName: 'crown',
    icon: [576, 512, [128081], "f521", "M309 106c11.4-7 19-19.7 19-34c0-22.1-17.9-40-40-40s-40 17.9-40 40c0 14.4 7.6 27 19 34l-5.8 11.6L209.7 220.6c-9.1 18.2-32.7 23.4-48.6 10.7l-72-57.6L72 160c5-6.7 8-15 8-24c0-22.1-17.9-40-40-40S0 113.9 0 136s17.9 40 40 40c.2 0 .5 0 .7 0l4.4 23.9L86.4 427.4c5.5 30.4 32 52.6 63 52.6l277.2 0c30.9 0 57.4-22.1 63-52.6l41.4-227.5 4.4-23.9c.2 0 .5 0 .7 0c22.1 0 40-17.9 40-40s-17.9-40-40-40s-40 17.9-40 40c0 9 3 17.3 8 24l-17.1 13.7-72 57.6c-15.9 12.7-39.5 7.5-48.6-10.7L314.8 117.7 309 106zM133.7 418.9L102.2 245.6l28.9 23.1c39.8 31.8 98.8 18.9 121.5-26.7L288 171.3l35.4 70.7c22.8 45.6 81.8 58.5 121.5 26.7l28.9-23.1L442.3 418.9c-1.4 7.6-8 13.1-15.7 13.1l-277.2 0c-7.7 0-14.4-5.5-15.7-13.1z"]
  };
  const faBooks = {
    prefix: 'far',
    iconName: 'books',
    icon: [512, 512, [128218], "f5db", "M48 416l0 48 64 0 0-48-64 0zm88 89.6c-7.1 4.1-15.3 6.4-24 6.4l-64 0c-26.5 0-48-21.5-48-48l0-48 0-24 0-24L0 144l0-24L0 96 0 48C0 21.5 21.5 0 48 0l64 0c8.7 0 16.9 2.3 24 6.4C143.1 2.3 151.3 0 160 0l64 0c20.6 0 38.1 12.9 45 31.1c5.6-6.1 12.9-10.7 21.4-13L349.9 1.6c24.7-6.8 50.1 8.3 56.7 33.8l18 69.2 6 23.2 61.8 238.3 6 23.2 11.9 46c6.6 25.5-8 51.7-32.7 58.5l-59.6 16.5c-24.7 6.8-50.1-8.3-56.7-33.8l-18-69.2-6-23.2L275.6 145.9 272 132.2l0 11.8 0 224 0 24 0 24 0 48c0 26.5-21.5 48-48 48l-64 0c-8.7 0-16.9-2.3-24-6.4zM160 464l64 0 0-48-64 0 0 48zM112 48L48 48l0 48 64 0 0-48zm0 96l-64 0 0 224 64 0 0-224zm48-48l64 0 0-48-64 0 0 48zm64 272l0-224-64 0 0 224 64 0zm216.1-12.3l-55.8-215-56.5 15.6 55.8 215 56.5-15.6zm-44.4 62.1l11.9 45.7L464 447.9c0-.1 0-.2 0-.3l0-.1-11.7-45.2-56.5 15.6zm-79.9-308l56.5-15.6L360.4 48.5 304 64.1c0 .1 0 .2 0 .4l11.7 45.2z"]
  };
  const faFolderOpen = {
    prefix: 'far',
    iconName: 'folder-open',
    icon: [576, 512, [128194, 128449, 61717], "f07c", "M384 480l48 0c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224l-400 0c-11.4 0-21.9 6-27.6 15.9L48 357.1 48 96c0-8.8 7.2-16 16-16l117.5 0c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8L416 144c8.8 0 16 7.2 16 16l0 32 48 0 0-32c0-35.3-28.7-64-64-64L298.5 96c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l23.7 0L384 480z"]
  };
  const faCassetteBetamax = {
    prefix: 'far',
    iconName: 'cassette-betamax',
    icon: [576, 512, ["betamax"], "f8a4", "M48 416l0-256 480 0 0 256c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM368 256l80 0 0 80-80 0 0-80zm-48-16l0 112c0 17.7 14.3 32 32 32l112 0c17.7 0 32-14.3 32-32l0-112c0-17.7-14.3-32-32-32l-112 0c-17.7 0-32 14.3-32 32zm-136 0a56 56 0 1 1 0 112 56 56 0 1 1 0-112zm0 160a104 104 0 1 0 0-208 104 104 0 1 0 0 208zm0-80a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"]
  };
  const faMessageLines = {
    prefix: 'far',
    iconName: 'message-lines',
    icon: [512, 512, ["comment-alt-lines"], "f4a6", "M208 416c0-26.5-21.5-48-48-48l-96 0c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l384 0c8.8 0 16 7.2 16 16l0 288c0 8.8-7.2 16-16 16l-138.7 0c-10.4 0-20.5 3.4-28.8 9.6L208 432l0-16zm-.2 76.2l.2-.2 101.3-76L448 416c35.3 0 64-28.7 64-64l0-288c0-35.3-28.7-64-64-64L64 0C28.7 0 0 28.7 0 64L0 352c0 35.3 28.7 64 64 64l48 0 48 0 0 48 0 4 0 .3 0 6.4 0 21.3c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L202.7 496l5.1-3.8zM152 144c-13.3 0-24 10.7-24 24s10.7 24 24 24l208 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-208 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l112 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-112 0z"]
  };
  const faCaretUp = {
    prefix: 'far',
    iconName: 'caret-up',
    icon: [320, 512, [], "f0d8", "M160 182.6L70.6 272l178.7 0L160 182.6zm-22.6-45.3c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8L32 320c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128z"]
  };
  const faTableColumns = {
    prefix: 'far',
    iconName: 'table-columns',
    icon: [512, 512, ["columns"], "f0db", "M48 416l0-256 184 0 0 272L64 432c-8.8 0-16-7.2-16-16zm232 16l0-272 184 0 0 256c0 8.8-7.2 16-16 16l-168 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z"]
  };
  const faCirclePlay = {
    prefix: 'far',
    iconName: 'circle-play',
    icon: [512, 512, [61469, "play-circle"], "f144", "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c7.6-4.2 16.8-4.1 24.3 .5l144 88c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.3 .5s-12.3-12.2-12.3-20.9l0-176c0-8.7 4.7-16.7 12.3-20.9z"]
  };
  const faCircleCheck = {
    prefix: 'far',
    iconName: 'circle-check',
    icon: [512, 512, [61533, "check-circle"], "f058", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"]
  };
  const faAddressCard = {
    prefix: 'far',
    iconName: 'address-card',
    icon: [576, 512, [62140, "contact-card", "vcard"], "f2bb", "M512 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l448 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM208 256a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm-32 32c-44.2 0-80 35.8-80 80c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16c0-44.2-35.8-80-80-80l-64 0zM376 144c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-80 0z"]
  };
  const faFileWord = {
    prefix: 'far',
    iconName: 'file-word',
    icon: [384, 512, [], "f1c2", "M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm55 241.1c-3.8-12.7-17.2-19.9-29.9-16.1s-19.9 17.2-16.1 29.9l48 160c3 10.2 12.4 17.1 23 17.1s19.9-7 23-17.1l25-83.4 25 83.4c3 10.2 12.4 17.1 23 17.1s19.9-7 23-17.1l48-160c3.8-12.7-3.4-26.1-16.1-29.9s-26.1 3.4-29.9 16.1l-25 83.4-25-83.4c-3-10.2-12.4-17.1-23-17.1s-19.9 7-23 17.1l-25 83.4-25-83.4z"]
  };
  const faBookAtlas = {
    prefix: 'far',
    iconName: 'book-atlas',
    icon: [448, 512, ["atlas"], "f558", "M0 88C0 39.4 39.4 0 88 0L392 0c30.9 0 56 25.1 56 56l0 288c0 22.3-13.1 41.6-32 50.6l0 69.4 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L80 512c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8L0 424 0 88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0 0-64L80 400zM48 358.7c9.8-4.3 20.6-6.7 32-6.7l312 0c4.4 0 8-3.6 8-8l0-288c0-4.4-3.6-8-8-8L88 48C65.9 48 48 65.9 48 88l0 270.7zM176.5 216l-31 0c4.8 26.1 21.1 48.2 43.5 60.8c-6.6-16.9-11-37.8-12.5-60.8zm0-32c1.4-22.9 5.9-43.8 12.5-60.8c-22.4 12.6-38.7 34.7-43.5 60.8l31 0zM112 200a120 120 0 1 1 240 0 120 120 0 1 1 -240 0zm206.5 16l-31 0c-1.4 22.9-5.9 43.8-12.5 60.8c22.4-12.6 38.7-34.7 43.5-60.8zM275 123.2c6.6 16.9 11 37.8 12.5 60.8l31 0c-4.8-26.1-21.1-48.2-43.5-60.8zM255.4 216l-46.9 0c1.6 22.6 6.5 41.8 12.8 55.3c4.7 10.1 8.6 14.2 10.6 15.8c2-1.6 5.9-5.7 10.6-15.8c6.3-13.5 11.2-32.7 12.8-55.3zm-46.9-32l46.9 0c-1.6-22.6-6.5-41.8-12.8-55.3c-4.7-10.1-8.6-14.2-10.6-15.8c-2 1.6-5.9 5.7-10.6 15.8c-6.3 13.5-11.2 32.7-12.8 55.3z"]
  };
  const faLayerGroup = {
    prefix: 'far',
    iconName: 'layer-group',
    icon: [576, 512, [], "f5fd", "M288 0c-8.5 0-17 1.7-24.8 5.1L53.9 94.8C40.6 100.5 32 113.5 32 128s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2L312.8 5.1C305 1.7 296.5 0 288 0zm-5.9 49.2C284 48.4 286 48 288 48s4 .4 5.9 1.2L477.7 128 293.9 206.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 128 282.1 49.2zM53.9 222.8C40.6 228.5 32 241.5 32 256s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 235.5 477.7 256 293.9 334.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 256 146 235.5 85.1 209.4 53.9 222.8zm0 128C40.6 356.5 32 369.5 32 384s8.6 27.5 21.9 33.2l209.3 89.7c7.8 3.4 16.3 5.1 24.8 5.1s17-1.7 24.8-5.1l209.3-89.7c13.3-5.7 21.9-18.8 21.9-33.2s-8.6-27.5-21.9-33.2l-31.2-13.4L430 363.5 477.7 384 293.9 462.8c-1.9 .8-3.9 1.2-5.9 1.2s-4-.4-5.9-1.2L98.3 384 146 363.5 85.1 337.4 53.9 350.8z"]
  };
  const faSquare = {
    prefix: 'far',
    iconName: 'square',
    icon: [448, 512, [9632, 9723, 9724, 61590], "f0c8", "M384 80c8.8 0 16 7.2 16 16l0 320c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l320 0zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32z"]
  };
  const faNewspaper = {
    prefix: 'far',
    iconName: 'newspaper',
    icon: [512, 512, [128240], "f1ea", "M168 80c-13.3 0-24 10.7-24 24l0 304c0 8.4-1.4 16.5-4.1 24L440 432c13.3 0 24-10.7 24-24l0-304c0-13.3-10.7-24-24-24L168 80zM72 480c-39.8 0-72-32.2-72-72L0 112C0 98.7 10.7 88 24 88s24 10.7 24 24l0 296c0 13.3 10.7 24 24 24s24-10.7 24-24l0-304c0-39.8 32.2-72 72-72l272 0c39.8 0 72 32.2 72 72l0 304c0 39.8-32.2 72-72 72L72 480zM176 136c0-13.3 10.7-24 24-24l96 0c13.3 0 24 10.7 24 24l0 80c0 13.3-10.7 24-24 24l-96 0c-13.3 0-24-10.7-24-24l0-80zm200-24l32 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-32 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 80l32 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-32 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zM200 272l208 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-208 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 80l208 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-208 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"]
  };
  const faRectangleAd = {
    prefix: 'far',
    iconName: 'rectangle-ad',
    icon: [576, 512, ["ad"], "f641", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l448 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm229.5 77.3l72 144c5.9 11.9 1.1 26.3-10.7 32.2s-26.3 1.1-32.2-10.7L253.2 328l-90.3 0-5.4 10.7c-5.9 11.9-20.3 16.7-32.2 10.7s-16.7-20.3-10.7-32.2l72-144c4.1-8.1 12.4-13.3 21.5-13.3s17.4 5.1 21.5 13.3zM208 237.7L186.8 280l42.3 0L208 237.7zM392 256a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm24-43.9l0-28.1c0-13.3 10.7-24 24-24s24 10.7 24 24l0 96 0 48c0 13.3-10.7 24-24 24c-6.6 0-12.6-2.7-17-7c-9.4 4.5-19.9 7-31 7c-39.8 0-72-32.2-72-72s32.2-72 72-72c8.4 0 16.5 1.4 24 4.1z"]
  };
  const faAd = faRectangleAd;
  const faCircleArrowRight = {
    prefix: 'far',
    iconName: 'circle-arrow-right',
    icon: [512, 512, ["arrow-circle-right"], "f0a9", "M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM305 361l88-88c9.4-9.4 9.4-24.6 0-33.9l-88-88c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l47 47L136 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l182.1 0-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0z"]
  };
  const faUserGroupCrown = {
    prefix: 'far',
    iconName: 'user-group-crown',
    icon: [640, 512, ["users-crown"], "f6a5", "M144 144c0 44.2 35.8 80 80 80s80-35.8 80-80l0-16-160 0 0 16zm-.1-124.9l.1 .1c10.2 7.5 23.8 8.3 34.9 2L209.1 4c4.6-2.6 9.7-4 14.9-4s10.4 1.4 14.9 4l30.2 17.2c11 6.3 24.7 5.5 34.9-2l.1-.1c.3-.2 .6-.4 .8-.6l3-2.4L323.6 3.5c2.8-2.3 6.4-3.5 10-3.5L336 0c8.8 0 16 7.2 16 16l0 23 0 3.2c0 0 0 .1 0 .1L352 144c0 70.7-57.3 128-128 128s-128-57.3-128-128L96 42.3c0 0 0-.1 0-.1L96 39l0-23c0-8.8 7.2-16 16-16l2.4 0c3.6 0 7.2 1.2 10 3.5L140 16l3 2.4c.3 .2 .6 .4 .8 .6zM48.3 464l351.5 0c-4.1-62.5-56.2-112-119.7-112l-112 0c-63.6 0-115.6 49.5-119.7 112zM0 472c0-92.8 75.2-168 168-168l112 0c92.8 0 168 75.2 168 168l0 8c0 17.7-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32l0-8zM432 256c-27.7 0-53-10.1-72.6-26.7c.5-.8 1-1.5 1.4-2.3c12.1-19.9 20-42.6 22.4-67c.5-5.3 .8-10.6 .8-16l0-101.2C398.5 35.9 414.8 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112zM609.3 512l-137.8 0c5.4-9.4 8.6-20.3 8.6-32l0-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2l61.4 0C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7z"]
  };
  const faFilter = {
    prefix: 'far',
    iconName: 'filter',
    icon: [512, 512, [], "f0b0", "M0 73.7C0 50.7 18.7 32 41.7 32l428.6 0c23 0 41.7 18.7 41.7 41.7c0 9.6-3.3 18.9-9.4 26.3L336 304.5l0 143.2c0 17.8-14.5 32.3-32.3 32.3c-7.3 0-14.4-2.5-20.1-7l-92.5-73.4c-9.6-7.6-15.1-19.1-15.1-31.3l0-63.7L9.4 100C3.3 92.6 0 83.3 0 73.7zM55 80L218.6 280.8c3.5 4.3 5.4 9.6 5.4 15.2l0 68.4 64 50.8L288 296c0-5.5 1.9-10.9 5.4-15.2L457 80 55 80z"]
  };
  const faPuzzlePiece = {
    prefix: 'far',
    iconName: 'puzzle-piece',
    icon: [512, 512, [129513], "f12e", "M201.1 57.3C194.1 62.5 192 68 192 72c0 4.2 2.4 10.1 10.4 15.6c7.8 5.3 13.6 14.6 13.6 25.6c0 17-13.8 30.7-30.7 30.7L56 144c-4.4 0-8 3.6-8 8l0 52.5c7.4-2.9 15.5-4.5 24-4.5c43.1 0 72 39.4 72 80s-28.9 80-72 80c-8.5 0-16.6-1.6-24-4.5L48 456c0 4.4 3.6 8 8 8l100.5 0c-2.9-7.4-4.5-15.5-4.5-24c0-43.1 39.4-72 80-72s80 28.9 80 72c0 8.5-1.6 16.6-4.5 24l52.5 0c4.4 0 8-3.6 8-8l0-129.3c0-17 13.8-30.7 30.7-30.7c11.1 0 20.3 5.8 25.6 13.6c5.5 8 11.4 10.4 15.6 10.4c4 0 9.5-2.1 14.7-9.1s9.3-17.9 9.3-30.9s-4-23.8-9.3-30.9s-10.7-9.1-14.7-9.1c-4.2 0-10.1 2.4-15.6 10.4c-5.3 7.8-14.6 13.6-25.6 13.6c-17 0-30.7-13.8-30.7-30.7l0-81.3c0-4.4-3.6-8-8-8l-81.3 0c-17 0-30.7-13.8-30.7-30.7c0-11.1 5.8-20.3 13.6-25.6c8-5.5 10.4-11.4 10.4-15.6c0-4-2.1-9.5-9.1-14.7S245 48 232 48s-23.8 4-30.9 9.3zM172.3 18.9C188.5 6.8 209.6 0 232 0s43.5 6.8 59.7 18.9S320 49.5 320 72c0 8.6-1.8 16.7-4.9 24L360 96c30.9 0 56 25.1 56 56l0 44.9c7.3-3.1 15.4-4.9 24-4.9c22.5 0 41 12.2 53.1 28.3S512 257.6 512 280s-6.8 43.5-18.9 59.7S462.5 368 440 368c-8.6 0-16.7-1.8-24-4.9l0 92.9c0 30.9-25.1 56-56 56l-78.1 0c-18.7 0-33.9-15.2-33.9-33.9c0-10.1 4.5-18.5 9.9-24.2c4.2-4.3 6.1-9.2 6.1-13.9c0-9.9-10.7-24-32-24s-32 14.1-32 24c0 4.7 1.9 9.5 6.1 13.9c5.5 5.7 9.9 14.1 9.9 24.2c0 18.7-15.2 33.9-33.9 33.9L56 512c-30.9 0-56-25.1-56-56L0 329.9C0 311.2 15.2 296 33.9 296c10.1 0 18.5 4.5 24.2 9.9c4.3 4.2 9.2 6.1 13.9 6.1c9.9 0 24-10.7 24-32s-14.1-32-24-32c-4.7 0-9.5 1.9-13.9 6.1C52.4 259.5 44 264 33.9 264C15.2 264 0 248.8 0 230.1L0 152c0-30.9 25.1-56 56-56l92.9 0c-3.1-7.3-4.9-15.4-4.9-24c0-22.5 12.2-41 28.3-53.1z"]
  };
  const faArrowUpRightFromSquare = {
    prefix: 'far',
    iconName: 'arrow-up-right-from-square',
    icon: [512, 512, ["external-link"], "f08e", "M304 24c0 13.3 10.7 24 24 24l102.1 0L207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l223-223L464 184c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160c0-13.3-10.7-24-24-24L328 0c-13.3 0-24 10.7-24 24zM72 32C32.2 32 0 64.2 0 104L0 440c0 39.8 32.2 72 72 72l336 0c39.8 0 72-32.2 72-72l0-128c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 128c0 13.3-10.7 24-24 24L72 464c-13.3 0-24-10.7-24-24l0-336c0-13.3 10.7-24 24-24l128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L72 32z"]
  };
  const faFileExcel = {
    prefix: 'far',
    iconName: 'file-excel',
    icon: [384, 512, [], "f1c3", "M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z"]
  };
  const faChartLine = {
    prefix: 'far',
    iconName: 'chart-line',
    icon: [512, 512, ["line-chart"], "f201", "M48 56c0-13.3-10.7-24-24-24S0 42.7 0 56L0 408c0 39.8 32.2 72 72 72l416 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L72 432c-13.3 0-24-10.7-24-24L48 56zM473 169c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119-79-79c-4.5-4.5-10.6-7-17-7s-12.5 2.5-17 7l-96 96c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l79-79 79 79c9.4 9.4 24.6 9.4 33.9 0L473 169z"]
  };
  const faArrowRight = {
    prefix: 'far',
    iconName: 'arrow-right',
    icon: [448, 512, [8594], "f061", "M440.6 273.4c4.7-4.5 7.4-10.8 7.4-17.4s-2.7-12.8-7.4-17.4l-176-168c-9.6-9.2-24.8-8.8-33.9 .8s-8.8 24.8 .8 33.9L364.1 232 24 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l340.1 0L231.4 406.6c-9.6 9.2-9.9 24.3-.8 33.9s24.3 9.9 33.9 .8l176-168z"]
  };
  const faBackwardFast = {
    prefix: 'far',
    iconName: 'backward-fast',
    icon: [512, 512, [9198, "fast-backward"], "f049", "M16 424c0 13.3 10.7 24 24 24s24-10.7 24-24l0-136.1L238.6 440.6c5.4 4.8 12.4 7.4 19.6 7.4c16.5 0 29.8-13.3 29.8-29.8l0-130.3L462.6 440.6c5.4 4.8 12.4 7.4 19.6 7.4c16.5 0 29.8-13.3 29.8-29.8l0-324.4C512 77.3 498.7 64 482.2 64c-7.2 0-14.2 2.6-19.6 7.4L288 224.1l0-130.3C288 77.3 274.7 64 258.2 64c-7.2 0-14.2 2.6-19.6 7.4L64 224.1 64 88c0-13.3-10.7-24-24-24S16 74.7 16 88l0 336zM464 133.9l0 244.2L324.4 256 464 133.9zM240 378.1L100.4 256 240 133.9l0 244.2z"]
  };
  const faVolumeHigh = {
    prefix: 'far',
    iconName: 'volume-high',
    icon: [640, 512, [128266, "volume-up"], "f028", "M533.6 32.5c-10.3-8.4-25.4-6.8-33.8 3.5s-6.8 25.4 3.5 33.8C557.5 113.8 592 180.8 592 256s-34.5 142.2-88.7 186.3c-10.3 8.4-11.8 23.5-3.5 33.8s23.5 11.8 33.8 3.5C598.5 426.7 640 346.2 640 256s-41.5-170.8-106.4-223.5zM473.1 107c-10.3-8.4-25.4-6.8-33.8 3.5s-6.8 25.4 3.5 33.8C475.3 170.7 496 210.9 496 256s-20.7 85.3-53.2 111.8c-10.3 8.4-11.8 23.5-3.5 33.8s23.5 11.8 33.8 3.5c43.2-35.2 70.9-88.9 70.9-149s-27.7-113.8-70.9-149zm-60.5 74.5c-10.3-8.4-25.4-6.8-33.8 3.5s-6.8 25.4 3.5 33.8C393.1 227.6 400 241 400 256s-6.9 28.4-17.7 37.3c-10.3 8.4-11.8 23.5-3.5 33.8s23.5 11.8 33.8 3.5C434.1 312.9 448 286.1 448 256s-13.9-56.9-35.4-74.5zM159.9 201.9L272 102.3l0 307.3L159.9 310.1c-4.4-3.9-10.1-6.1-15.9-6.1l-88 0c-4.4 0-8-3.6-8-8l0-80c0-4.4 3.6-8 8-8l88 0c5.9 0 11.6-2.2 15.9-6.1zM290.2 32c-7.3 0-14.3 2.7-19.8 7.5L134.9 160 56 160c-30.9 0-56 25.1-56 56l0 80c0 30.9 25.1 56 56 56l78.9 0L270.4 472.5c5.5 4.9 12.5 7.5 19.8 7.5c16.5 0 29.8-13.3 29.8-29.8l0-388.4C320 45.3 306.7 32 290.2 32z"]
  };
  const faFileAudio = {
    prefix: 'far',
    iconName: 'file-audio',
    icon: [384, 512, [], "f1c7", "M64 464l256 0c8.8 0 16-7.2 16-16l0-288-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16zM0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 448c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64zM192 272l0 128c0 6.5-3.9 12.3-9.9 14.8s-12.9 1.1-17.4-3.5L129.4 376 112 376c-8.8 0-16-7.2-16-16l0-48c0-8.8 7.2-16 16-16l17.4 0 35.3-35.3c4.6-4.6 11.5-5.9 17.4-3.5s9.9 8.3 9.9 14.8zm85.8-4c11.6 20 18.2 43.3 18.2 68s-6.6 48-18.2 68c-6.6 11.5-21.3 15.4-32.8 8.8s-15.4-21.3-8.8-32.8c7.5-12.9 11.8-27.9 11.8-44s-4.3-31.1-11.8-44c-6.6-11.5-2.7-26.2 8.8-32.8s26.2-2.7 32.8 8.8z"]
  };
  const faFileImage = {
    prefix: 'far',
    iconName: 'file-image',
    icon: [384, 512, [128443], "f1c5", "M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zm96 256a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm69.2 46.9c-3-4.3-7.9-6.9-13.2-6.9s-10.2 2.6-13.2 6.9l-41.3 59.7-11.9-19.1c-2.9-4.7-8.1-7.5-13.6-7.5s-10.6 2.8-13.6 7.5l-40 64c-3.1 4.9-3.2 11.1-.4 16.2s8.2 8.2 14 8.2l48 0 32 0 40 0 72 0c6 0 11.4-3.3 14.2-8.6s2.4-11.6-1-16.5l-72-104z"]
  };
  const faCircleQuestion = {
    prefix: 'far',
    iconName: 'circle-question',
    icon: [512, 512, [62108, "question-circle"], "f059", "M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  const faForwardFast = {
    prefix: 'far',
    iconName: 'forward-fast',
    icon: [512, 512, [9197, "fast-forward"], "f050", "M496 424c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-136.1L273.4 440.6c-5.4 4.8-12.4 7.4-19.6 7.4c-16.5 0-29.8-13.3-29.8-29.8l0-130.3L49.4 440.6C44 445.4 37 448 29.8 448C13.3 448 0 434.7 0 418.2L0 93.8C0 77.3 13.3 64 29.8 64C37 64 44 66.6 49.4 71.4L224 224.1l0-130.3C224 77.3 237.3 64 253.8 64c7.2 0 14.2 2.6 19.6 7.4L448 224.1 448 88c0-13.3 10.7-24 24-24s24 10.7 24 24l0 336zM48 133.9l0 244.2L187.6 256 48 133.9zM272 378.1L411.6 256 272 133.9l0 244.2z"]
  };
  const faEye = {
    prefix: 'far',
    iconName: 'eye',
    icon: [576, 512, [128065], "f06e", "M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"]
  };
  const faUserSlash = {
    prefix: 'far',
    iconName: 'user-slash',
    icon: [640, 512, [], "f506", "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L353.3 251.6C407.9 237 448 187.2 448 128C448 57.3 390.7 0 320 0C250.2 0 193.5 55.8 192 125.2L38.8 5.1zM290.5 202.4l-35.6-27.9C245.5 161.4 240 145.3 240 128c0-44.2 35.8-80 80-80s80 35.8 80 80s-35.8 80-80 80c-10.4 0-20.4-2-29.5-5.6zM264.3 304.3C170.5 309.4 96 387.2 96 482.3c0 16.4 13.3 29.7 29.7 29.7l388.6 0c3.9 0 7.6-.7 11-2.1L467.1 464l-321.8 0c8.9-63.3 63.3-112 129-112l50.6 0-60.6-47.7z"]
  };
  const faPen = {
    prefix: 'far',
    iconName: 'pen',
    icon: [512, 512, [128394], "f304", "M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c12.4-3.6 23.7-9.9 33.4-18.4c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4zm46 13.5c1.7-5.6 4.5-10.8 8.4-15.2c.6-.6 1.1-1.2 1.7-1.8L321 129 383 191 154.6 419.5c-4.7 4.7-10.6 8.2-17 10.1l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4z"]
  };
  const faFloppyDisk = {
    prefix: 'far',
    iconName: 'floppy-disk',
    icon: [448, 512, [128190, 128426, "save"], "f0c7", "M48 96l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-245.5c0-4.2-1.7-8.3-4.7-11.3l33.9-33.9c12 12 18.7 28.3 18.7 45.3L448 416c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l245.5 0c17 0 33.3 6.7 45.3 18.7l74.5 74.5-33.9 33.9L320.8 84.7c-.3-.3-.5-.5-.8-.8L320 184c0 13.3-10.7 24-24 24l-192 0c-13.3 0-24-10.7-24-24L80 80 64 80c-8.8 0-16 7.2-16 16zm80-16l0 80 144 0 0-80L128 80zm32 240a64 64 0 1 1 128 0 64 64 0 1 1 -128 0z"]
  };
  const faBooksMedical = {
    prefix: 'far',
    iconName: 'books-medical',
    icon: [576, 512, [], "f7e8", "M112 416l0 48 64 0 0-48-64 0zm88 89.6c-7.1 4.1-15.3 6.4-24 6.4l-64 0c-26.5 0-48-21.5-48-48l0-48 0-24 0-8 112 0 0-16 0-48 48 0 0 48 64 0 0-224-64 0 0 48-48 0 0-48 0-16L64 128l0-8 0-24 0-48C64 21.5 85.5 0 112 0l64 0c8.7 0 16.9 2.3 24 6.4C207.1 2.3 215.3 0 224 0l64 0c20.6 0 38.1 12.9 45 31.1c5.6-6.1 12.9-10.7 21.4-13L413.9 1.6c24.7-6.8 50.1 8.3 56.7 33.8l18 69.2 6 23.2 61.8 238.3 6 23.2 11.9 46c6.6 25.5-8 51.7-32.7 58.5l-59.6 16.5c-24.7 6.8-50.1-8.3-56.7-33.8l-18-69.2-6-23.2L339.6 145.9 336 132.2l0 11.8 0 224 0 24 0 24 0 48c0 26.5-21.5 48-48 48l-64 0c-8.7 0-16.9-2.3-24-6.4zM224 464l64 0 0-48-64 0 0 48zM176 48l-64 0 0 48 64 0 0-48zm48 48l64 0 0-48-64 0 0 48zM504.1 355.7l-55.8-215-56.5 15.6 55.8 215 56.5-15.6zm-44.4 62.1l11.9 45.7L528 447.9c0-.1 0-.2 0-.3l0-.1-11.7-45.2-56.5 15.6zm-79.9-308l56.5-15.6L424.4 48.5 368 64.1c0 .1 0 .2 0 .4l11.7 45.2zM64 176c0-8.8 7.2-16 16-16l32 0c8.8 0 16 7.2 16 16l0 48 48 0c8.8 0 16 7.2 16 16l0 32c0 8.8-7.2 16-16 16l-48 0 0 48c0 8.8-7.2 16-16 16l-32 0c-8.8 0-16-7.2-16-16l0-48-48 0c-8.8 0-16-7.2-16-16l0-32c0-8.8 7.2-16 16-16l48 0 0-48z"]
  };
  const faUserGear = {
    prefix: 'far',
    iconName: 'user-gear',
    icon: [640, 512, ["user-cog"], "f4fe", "M224 208a80 80 0 1 0 0-160 80 80 0 1 0 0 160zM224 0a128 128 0 1 1 0 256A128 128 0 1 1 224 0zM49.3 464l287.3 0c3.3 4.2 6.7 8.2 10.3 12c15.7 16.9 39.6 18.4 57.2 8.7l0 .9c0 9.2 2.7 18.5 7.9 26.3L29.7 512C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304l45.7 0 45.7 0c11.8 0 23.4 1.2 34.5 3.3c-2.1 18.5 7.4 35.6 21.8 44.8c-3.6 2.3-7 5.1-9.9 8.4c-14.4-5.5-30.1-8.5-46.5-8.5l-91.4 0c-65.7 0-120.1 48.7-129 112zM436 218.2c0-7 4.5-13.3 11.3-14.8c10.5-2.4 21.5-3.7 32.7-3.7s22.2 1.3 32.7 3.7c6.8 1.5 11.3 7.8 11.3 14.8l0 30.6c7.9 3.4 15.4 7.7 22.3 12.8l24.9-14.3c6.1-3.5 13.7-2.7 18.5 2.4c7.6 8.1 14.3 17.2 20.1 27.2s10.3 20.4 13.5 31c2.1 6.7-1.1 13.7-7.2 17.2l-25 14.4c.4 4 .7 8.1 .7 12.3s-.2 8.2-.7 12.3l25 14.4c6.1 3.5 9.2 10.5 7.2 17.2c-3.3 10.6-7.8 21-13.5 31s-12.5 19.1-20.1 27.2c-4.8 5.1-12.5 5.9-18.5 2.4l-24.9-14.3c-6.9 5.1-14.3 9.4-22.3 12.8l0 30.6c0 7-4.5 13.3-11.3 14.8c-10.5 2.4-21.5 3.7-32.7 3.7s-22.2-1.3-32.7-3.7c-6.8-1.5-11.3-7.8-11.3-14.8l0-30.5c-8-3.4-15.6-7.7-22.5-12.9l-24.7 14.3c-6.1 3.5-13.7 2.7-18.5-2.4c-7.6-8.1-14.3-17.2-20.1-27.2s-10.3-20.4-13.5-31c-2.1-6.7 1.1-13.7 7.2-17.2l24.8-14.3c-.4-4.1-.7-8.2-.7-12.4s.2-8.3 .7-12.4L343.8 325c-6.1-3.5-9.2-10.5-7.2-17.2c3.3-10.6 7.7-21 13.5-31s12.5-19.1 20.1-27.2c4.8-5.1 12.4-5.9 18.5-2.4l24.8 14.3c6.9-5.1 14.5-9.4 22.5-12.9l0-30.5zm92.1 133.5a48.1 48.1 0 1 0 -96.1 0 48.1 48.1 0 1 0 96.1 0z"]
  };
  const faPenFancy = {
    prefix: 'far',
    iconName: 'pen-fancy',
    icon: [512, 512, [128395, 10002], "f5ac", "M399.4 59.9c7.1-7.6 17-11.9 27.3-11.9C447.3 48 464 64.7 464 85.3c0 10.4-4.3 20.2-11.9 27.3l-195.5 182-39.2-39.2 182-195.5zM426.7 0C403 0 380.4 9.8 364.2 27.2L170.8 234.9 97.2 257.6c-22.8 7-40.6 24.9-47.6 47.6L1.9 460.1c-9.4 30.7 19.3 59.4 50 50l154.8-47.6c22.8-7 40.6-24.9 47.6-47.6l22.6-73.6L484.8 147.8C502.2 131.6 512 109 512 85.3C512 38.2 473.8 0 426.7 0zM228.8 334.8l-20.3 65.9c-2.3 7.6-8.3 13.5-15.9 15.9L85 449.7 134.7 400c.4 0 .9 0 1.3 0c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24c0 .4 0 .9 0 1.3L62.3 427 95.5 319.3c2.3-7.6 8.3-13.5 15.9-15.9l65.9-20.3 51.6 51.6z"]
  };
  const faShirt = {
    prefix: 'far',
    iconName: 'shirt',
    icon: [640, 512, [128085, "t-shirt", "tshirt"], "f553", "M146.3 27.8C167.9 9.8 195.1 0 223.2 0c18.2 0 31.1 13.6 34 28.5C263 57.8 288.9 80 320 80s57-22.2 62.8-51.5C385.7 13.6 398.7 0 416.8 0c28.1 0 55.3 9.8 76.8 27.8L478.3 46.3l15.4-18.4L619.8 133c11.6 9.7 18.8 23.6 20 38.6s-3.7 29.9-13.7 41.3l-56 64c-20 22.9-54.6 25.6-78 6.1L480 272.9 480 448c0 35.3-28.7 64-64 64l-192 0c-35.3 0-64-28.7-64-64l0-175.1L147.9 283c-23.4 19.5-58 16.7-78-6.1l-56-64C3.9 201.5-1 186.6 .2 171.6s8.4-29 20-38.6L146.3 27.8zm66.5 20.9c-13.1 1.9-25.5 7.4-35.8 15.9L50.9 169.9c-1.7 1.4-2.7 3.4-2.9 5.5s.5 4.3 2 5.9L31.9 197.1 50 181.3l56 64c2.9 3.3 7.8 3.7 11.1 .9l51.5-42.9c7.2-6 17.1-7.2 25.5-3.3s13.8 12.4 13.8 21.7L208 448c0 8.8 7.2 16 16 16l192 0c8.8 0 16-7.2 16-16l0-226.3c0-9.3 5.4-17.8 13.8-21.7s18.4-2.7 25.5 3.3l51.5 42.9c3.3 2.8 8.3 2.4 11.1-.9l56-64c1.4-1.6 2.1-3.7 2-5.9s-1.2-4.1-2.9-5.5L462.9 64.7c-10.3-8.6-22.7-14.1-35.8-15.9C413.1 94.6 370.5 128 320 128s-93.1-33.4-107.1-79.3z"]
  };
  const faHeadphones = {
    prefix: 'far',
    iconName: 'headphones',
    icon: [512, 512, [127911], "f025", "M49.6 262C62.4 159.4 149.9 80 256 80s193.6 79.4 206.4 182c-9.4-3.9-19.6-6-30.4-6c-26.5 0-48 21.5-48 48l0 128c0 26.5 21.5 48 48 48c44.2 0 80-35.8 80-80l0-32 0-32 0-48C512 146.6 397.4 32 256 32S0 146.6 0 288l0 48 0 32 0 32c0 44.2 35.8 80 80 80c26.5 0 48-21.5 48-48l0-128c0-26.5-21.5-48-48-48c-10.8 0-21 2.1-30.4 6zM48 336c0-17.7 14.3-32 32-32l0 128c-17.7 0-32-14.3-32-32l0-32 0-32zm416 0l0 32 0 32c0 17.7-14.3 32-32 32l0-128c17.7 0 32 14.3 32 32z"]
  };
  const faUpRightFromSquare = {
    prefix: 'far',
    iconName: 'up-right-from-square',
    icon: [512, 512, ["external-link-alt"], "f35d", "M304 41c0 10.9 4.3 21.3 12 29L362.1 116 207 271c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l155-155L442.1 196c7.7 7.7 18.1 12 29 12c22.6 0 41-18.3 41-41l0-127c0-22.1-17.9-40-40-40L345 0c-22.6 0-41 18.3-41 41zm57.9 7L464 48l0 102.1L361.9 48zM72 32C32.2 32 0 64.2 0 104L0 440c0 39.8 32.2 72 72 72l336 0c39.8 0 72-32.2 72-72l0-128c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 128c0 13.3-10.7 24-24 24L72 464c-13.3 0-24-10.7-24-24l0-336c0-13.3 10.7-24 24-24l128 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L72 32z"]
  };
  const faGamepadModern = {
    prefix: 'far',
    iconName: 'gamepad-modern',
    icon: [640, 512, [127918, 63676, "gamepad-alt"], "e5a2", "M216 336l208 0c22 0 41.2 15 46.6 36.4l3.6 14.3c6.7 26.6 30.6 45.3 58 45.3c33 0 59.8-26.8 59.8-59.8l0-2.8c0-3.5-.3-7.1-.9-10.6L558.4 168c-6.2-36-27.8-60.4-54.6-67.1C461.4 90.3 399.7 80 320 80s-141.5 10.3-183.7 20.9C109.4 107.6 87.8 132 81.6 168L48.9 358.8c-.6 3.5-.9 7-.9 10.6l0 2.8c0 33 26.8 59.8 59.8 59.8c27.4 0 51.4-18.7 58-45.3l3.6-14.3C174.8 351 194 336 216 336zm211.6 62.3L424 384l-208 0-3.6 14.3c-12 48-55.1 81.7-104.6 81.7C48.3 480 0 431.7 0 372.2l0-2.8c0-6.3 .5-12.5 1.6-18.7L34.3 159.8c8.6-50.2 40.9-93.2 90.3-105.5C170.5 42.9 236.2 32 320 32s149.5 10.9 195.3 22.3c49.4 12.3 81.7 55.3 90.3 105.5l32.7 190.9c1.1 6.2 1.6 12.4 1.6 18.7l0 2.8C640 431.7 591.7 480 532.2 480c-49.5 0-92.6-33.7-104.6-81.7zM232 168l0 32 32 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-32 0 0 32c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-32-32 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l32 0 0-32c0-13.3 10.7-24 24-24s24 10.7 24 24zm168 72a32 32 0 1 1 0 64 32 32 0 1 1 0-64zm32-64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  const faBookSparkles = {
    prefix: 'far',
    iconName: 'book-sparkles',
    icon: [448, 512, ["book-spells"], "f6b8", "M0 88C0 39.4 39.4 0 88 0L392 0c30.9 0 56 25.1 56 56l0 288c0 22.3-13.1 41.6-32 50.6l0 69.4 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L80 512c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8L0 424 0 88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0 0-64L80 400zM48 358.7c9.8-4.3 20.6-6.7 32-6.7l312 0c4.4 0 8-3.6 8-8l0-288c0-4.4-3.6-8-8-8L88 48C65.9 48 48 65.9 48 88l0 270.7zM160 112l8.8-17.7c2.9-5.9 11.4-5.9 14.3 0L192 112l17.7 8.8c5.9 2.9 5.9 11.4 0 14.3L192 144l-8.8 17.7c-2.9 5.9-11.4 5.9-14.3 0L160 144l-17.7-8.8c-5.9-2.9-5.9-11.4 0-14.3L160 112zM264 216l16.6-38.8c2.8-6.5 11.9-6.5 14.7 0L312 216l38.8 16.6c6.5 2.8 6.5 11.9 0 14.7L312 264l-16.6 38.8c-2.8 6.5-11.9 6.5-14.7 0L264 264l-38.8-16.6c-6.5-2.8-6.5-11.9 0-14.7L264 216z"]
  };
  const faChild = {
    prefix: 'far',
    iconName: 'child',
    icon: [320, 512, [], "f1ae", "M160 96a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-96a72 72 0 1 1 0 144A72 72 0 1 1 160 0zM128 214l0 122 64 0 0-121.6c-10.2-4.2-21.2-6.4-32.4-6.4c-11 0-21.6 2.1-31.6 6zm0 170l0 104c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-230.1L43.7 309.8c-7.6 10.9-22.6 13.5-33.4 5.9S-3.3 293.1 4.3 282.2L50.1 217c25-35.7 65.9-57 109.5-57c43.2 0 83.7 20.8 108.8 56L315.5 282c7.7 10.8 5.2 25.8-5.6 33.5s-25.8 5.2-33.5-5.6L240 258.9 240 488c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-104-64 0z"]
  };
  const faTag = {
    prefix: 'far',
    iconName: 'tag',
    icon: [448, 512, [127991], "f02b", "M197.5 32c17 0 33.3 6.7 45.3 18.7l176 176c25 25 25 65.5 0 90.5L285.3 450.7c-25 25-65.5 25-90.5 0l-176-176C6.7 262.7 0 246.5 0 229.5L0 80C0 53.5 21.5 32 48 32l149.5 0zM48 229.5c0 4.2 1.7 8.3 4.7 11.3l176 176c6.2 6.2 16.4 6.2 22.6 0L384.8 283.3c6.2-6.2 6.2-16.4 0-22.6l-176-176c-3-3-7.1-4.7-11.3-4.7L48 80l0 149.5zM112 112a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
  };
  const faEnvelope = {
    prefix: 'far',
    iconName: 'envelope',
    icon: [512, 512, [128386, 9993, 61443], "f0e0", "M64 112c-8.8 0-16 7.2-16 16l0 22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1l0-22.1c0-8.8-7.2-16-16-16L64 112zM48 212.2L48 384c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-171.8L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64l384 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128z"]
  };
  const faAnglesUp = {
    prefix: 'far',
    iconName: 'angles-up',
    icon: [448, 512, ["angle-double-up"], "f102", "M241 47c-9.4-9.4-24.6-9.4-33.9 0L47 207c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l143-143L367 241c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L241 47zM401 399L241 239c-9.4-9.4-24.6-9.4-33.9 0L47 399c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l143-143L367 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9z"]
  };
  const faCircleSmall = {
    prefix: 'far',
    iconName: 'circle-small',
    icon: [320, 512, [], "e122", "M272 256A112 112 0 1 0 48 256a112 112 0 1 0 224 0zM0 256a160 160 0 1 1 320 0A160 160 0 1 1 0 256z"]
  };
  const faCircleInfo = {
    prefix: 'far',
    iconName: 'circle-info',
    icon: [512, 512, ["info-circle"], "f05a", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336c-13.3 0-24 10.7-24 24s10.7 24 24 24l80 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-8 0 0-88c0-13.3-10.7-24-24-24l-48 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l24 0 0 64-24 0zm40-144a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"]
  };
  const faRectangleList = {
    prefix: 'far',
    iconName: 'rectangle-list',
    icon: [576, 512, ["list-alt"], "f022", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l448 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l448 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm96 64a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm104 0c0-13.3 10.7-24 24-24l224 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-224 0c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24l224 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-224 0c-13.3 0-24-10.7-24-24zm0 96c0-13.3 10.7-24 24-24l224 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-224 0c-13.3 0-24-10.7-24-24zm-72-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM96 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  const faPresentationScreen = {
    prefix: 'far',
    iconName: 'presentation-screen',
    icon: [576, 512, ["presentation"], "f685", "M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48l528 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 0zm8 80l0 216c0 30.9 25.1 56 56 56l176 0 0 46.1-73 73c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l63-63 63 63c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-73-73 0-46.1 176 0c30.9 0 56-25.1 56-56l0-216-48 0 0 216c0 4.4-3.6 8-8 8l-200 0L88 304c-4.4 0-8-3.6-8-8L80 80 32 80z"]
  };
  const faFileCertificate = {
    prefix: 'far',
    iconName: 'file-certificate',
    icon: [512, 512, ["file-award"], "f5f3", "M448 464l-224 0 0 32c0 5.5-1 10.9-2.7 16L448 512c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L402.7 18.7C390.7 6.7 374.5 0 357.5 0L192 0c-35.3 0-64 28.7-64 64l0 71.1 .1-.1c1.5-.7 4-2 6.6-3c13.6-5.5 28.3-5.4 41.3-.5L176 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16zM109.2 161.6c-10-4.1-21.5-1-28.1 7.5L70.6 182.6c-1.3 1.7-3.2 2.7-5.2 3l-16.9 2.3c-10.7 1.5-19.1 9.9-20.5 20.5l-2.3 16.9c-.3 2.1-1.4 4-3 5.2L9.1 241.1c-8.5 6.6-11.6 18.1-7.5 28.1L8 285c.8 1.9 .8 4.1 0 6.1L1.6 306.8c-4.1 10-1 21.5 7.5 28.1l13.5 10.5c1.7 1.3 2.7 3.2 3 5.2l2.3 16.9c1.5 10.7 9.9 19.1 20.5 20.6L64 390.2 64 496c0 5.9 3.2 11.3 8.5 14.1s11.5 2.5 16.4-.8L128 483.2l39.1 26.1c4.9 3.3 11.2 3.6 16.4 .8s8.5-8.2 8.5-14.1l0-105.8 15.5-2.1c10.7-1.5 19.1-9.9 20.5-20.6l2.3-16.9c.3-2.1 1.4-4 3-5.2l13.5-10.5c8.5-6.6 11.6-18.1 7.5-28.1L248 291c-.8-1.9-.8-4.1 0-6.1l6.5-15.8c4.1-10 1-21.5-7.5-28.1l-13.5-10.5c-1.7-1.3-2.7-3.2-3-5.2l-2.3-16.9c-1.5-10.7-9.9-19.1-20.5-20.5l-16.9-2.3c-2.1-.3-4-1.4-5.2-3l-10.5-13.5c-6.6-8.5-18.1-11.6-28.1-7.5L131 168c-1.9 .8-4.1 .8-6.1 0l-15.8-6.5zM64 288a64 64 0 1 1 128 0A64 64 0 1 1 64 288z"]
  };
  const faGear = {
    prefix: 'far',
    iconName: 'gear',
    icon: [512, 512, [9881, "cog"], "f013", "M256 0c17 0 33.6 1.7 49.8 4.8c7.9 1.5 21.8 6.1 29.4 20.1c2 3.7 3.6 7.6 4.6 11.8l9.3 38.5C350.5 81 360.3 86.7 366 85l38-11.2c4-1.2 8.1-1.8 12.2-1.9c16.1-.5 27 9.4 32.3 15.4c22.1 25.1 39.1 54.6 49.9 86.3c2.6 7.6 5.6 21.8-2.7 35.4c-2.2 3.6-4.9 7-8 10L459 246.3c-4.2 4-4.2 15.5 0 19.5l28.7 27.3c3.1 3 5.8 6.4 8 10c8.2 13.6 5.2 27.8 2.7 35.4c-10.8 31.7-27.8 61.1-49.9 86.3c-5.3 6-16.3 15.9-32.3 15.4c-4.1-.1-8.2-.8-12.2-1.9L366 427c-5.7-1.7-15.5 4-16.9 9.8l-9.3 38.5c-1 4.2-2.6 8.2-4.6 11.8c-7.7 14-21.6 18.5-29.4 20.1C289.6 510.3 273 512 256 512s-33.6-1.7-49.8-4.8c-7.9-1.5-21.8-6.1-29.4-20.1c-2-3.7-3.6-7.6-4.6-11.8l-9.3-38.5c-1.4-5.8-11.2-11.5-16.9-9.8l-38 11.2c-4 1.2-8.1 1.8-12.2 1.9c-16.1 .5-27-9.4-32.3-15.4c-22-25.1-39.1-54.6-49.9-86.3c-2.6-7.6-5.6-21.8 2.7-35.4c2.2-3.6 4.9-7 8-10L53 265.7c4.2-4 4.2-15.5 0-19.5L24.2 218.9c-3.1-3-5.8-6.4-8-10C8 195.3 11 181.1 13.6 173.6c10.8-31.7 27.8-61.1 49.9-86.3c5.3-6 16.3-15.9 32.3-15.4c4.1 .1 8.2 .8 12.2 1.9L146 85c5.7 1.7 15.5-4 16.9-9.8l9.3-38.5c1-4.2 2.6-8.2 4.6-11.8c7.7-14 21.6-18.5 29.4-20.1C222.4 1.7 239 0 256 0zM218.1 51.4l-8.5 35.1c-7.8 32.3-45.3 53.9-77.2 44.6L97.9 120.9c-16.5 19.3-29.5 41.7-38 65.7l26.2 24.9c24 22.8 24 66.2 0 89L59.9 325.4c8.5 24 21.5 46.4 38 65.7l34.6-10.2c31.8-9.4 69.4 12.3 77.2 44.6l8.5 35.1c24.6 4.5 51.3 4.5 75.9 0l8.5-35.1c7.8-32.3 45.3-53.9 77.2-44.6l34.6 10.2c16.5-19.3 29.5-41.7 38-65.7l-26.2-24.9c-24-22.8-24-66.2 0-89l26.2-24.9c-8.5-24-21.5-46.4-38-65.7l-34.6 10.2c-31.8 9.4-69.4-12.3-77.2-44.6l-8.5-35.1c-24.6-4.5-51.3-4.5-75.9 0zM208 256a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192z"]
  };
  const faBookHeart = {
    prefix: 'far',
    iconName: 'book-heart',
    icon: [448, 512, [], "f499", "M0 88C0 39.4 39.4 0 88 0L392 0c30.9 0 56 25.1 56 56l0 288c0 22.3-13.1 41.6-32 50.6l0 69.4 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L80 512c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8L0 424 0 88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0 0-64L80 400zM48 358.7c9.8-4.3 20.6-6.7 32-6.7l312 0c4.4 0 8-3.6 8-8l0-288c0-4.4-3.6-8-8-8L88 48C65.9 48 48 65.9 48 88l0 270.7zm80-185.4c0-33.8 27.4-61.3 61.3-61.3c16.2 0 31.8 6.5 43.3 17.9l7.4 7.4 7.4-7.4c11.5-11.5 27.1-17.9 43.3-17.9c33.8 0 61.3 27.4 61.3 61.3c0 16.2-6.5 31.8-17.9 43.3l-82.7 82.7c-6.2 6.2-16.4 6.2-22.6 0l-82.7-82.7c-11.5-11.5-17.9-27.1-17.9-43.3z"]
  };
  const faCartShopping = {
    prefix: 'far',
    iconName: 'cart-shopping',
    icon: [576, 512, [128722, "shopping-cart"], "f07a", "M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48l45.5 0c3.8 0 7.1 2.7 7.9 6.5l51.6 271c6.5 34 36.2 58.5 70.7 58.5L488 384c13.3 0 24-10.7 24-24s-10.7-24-24-24l-288.3 0c-11.5 0-21.4-8.2-23.6-19.5L170.7 288l288.5 0c32.6 0 61.1-21.8 69.5-53.3l41-152.3C576.6 57 557.4 32 531.1 32l-411 0C111 12.8 91.6 0 69.5 0L24 0zM131.1 80l389.6 0L482.4 222.2c-2.8 10.5-12.3 17.8-23.2 17.8l-297.6 0L131.1 80zM176 512a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm336-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0z"]
  };
  const faFilmCanister = {
    prefix: 'far',
    iconName: 'film-canister',
    icon: [576, 512, ["film-cannister"], "f8b7", "M96 32c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32l40 0 32 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-8 0 0 384 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-32 0L56 512l-32 0c-13.3 0-24-10.7-24-24s10.7-24 24-24l8 0L32 80l-8 0C10.7 80 0 69.3 0 56S10.7 32 24 32l32 0 40 0zM80 80l0 384 192 0 0-384L80 80zm464 48c17.7 0 32 14.3 32 32l0 128c0 17.7-14.3 32-32 32s-32 14.3-32 32l0 32c0 17.7-14.3 32-32 32l-128 0 0-288 192 0zM392 184l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16zm112-16c-8.8 0-16 7.2-16 16l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0zM392 344l0 16c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-16c0-8.8-7.2-16-16-16l-16 0c-8.8 0-16 7.2-16 16z"]
  };
  const faUsbDrive = {
    prefix: 'far',
    iconName: 'usb-drive',
    icon: [640, 512, [], "f8e9", "M64 144c-8.8 0-16 7.2-16 16l0 192c0 8.8 7.2 16 16 16l336 0 0-224L64 144zM0 160c0-35.3 28.7-64 64-64l352 0c17.7 0 32 14.3 32 32l0 256c0 17.7-14.3 32-32 32L64 416c-35.3 0-64-28.7-64-64L0 160zm608-32c17.7 0 32 14.3 32 32l0 192c0 17.7-14.3 32-32 32l-128 0 0-256 128 0zm-48 56a24 24 0 1 0 0 48 24 24 0 1 0 0-48zM536 304a24 24 0 1 0 48 0 24 24 0 1 0 -48 0z"]
  };
  const faBallot = {
    prefix: 'far',
    iconName: 'ballot',
    icon: [448, 512, [], "f732", "M384 48c8.8 0 16 7.2 16 16l0 384c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l320 0zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L64 0zM80 112l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16L96 96c-8.8 0-16 7.2-16 16zM96 224c-8.8 0-16 7.2-16 16l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0zM80 368l0 32c0 8.8 7.2 16 16 16l32 0c8.8 0 16-7.2 16-16l0-32c0-8.8-7.2-16-16-16l-32 0c-8.8 0-16 7.2-16 16zM200 104c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0zm0 128c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0zm0 128c-13.3 0-24 10.7-24 24s10.7 24 24 24l144 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-144 0z"]
  };
  const faCaretDown = {
    prefix: 'far',
    iconName: 'caret-down',
    icon: [320, 512, [], "f0d7", "M160 329.4L249.4 240 70.6 240 160 329.4zm22.6 45.3c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8l256 0c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128z"]
  };
  const faImages = {
    prefix: 'far',
    iconName: 'images',
    icon: [576, 512, [], "f302", "M160 80l352 0c8.8 0 16 7.2 16 16l0 224c0 8.8-7.2 16-16 16l-21.2 0L388.1 178.9c-4.4-6.8-12-10.9-20.1-10.9s-15.7 4.1-20.1 10.9l-52.2 79.8-12.4-16.9c-4.5-6.2-11.7-9.8-19.4-9.8s-14.8 3.6-19.4 9.8L175.6 336 160 336c-8.8 0-16-7.2-16-16l0-224c0-8.8 7.2-16 16-16zM96 96l0 224c0 35.3 28.7 64 64 64l352 0c35.3 0 64-28.7 64-64l0-224c0-35.3-28.7-64-64-64L160 32c-35.3 0-64 28.7-64 64zM48 120c0-13.3-10.7-24-24-24S0 106.7 0 120L0 344c0 75.1 60.9 136 136 136l320 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-320 0c-48.6 0-88-39.4-88-88l0-224zm208 24a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"]
  };
  const faDownload = {
    prefix: 'far',
    iconName: 'download',
    icon: [512, 512, [], "f019", "M280 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 270.1-95-95c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 369c9.4 9.4 24.6 9.4 33.9 0L409 233c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95L280 24zM128.8 304L64 304c-35.3 0-64 28.7-64 64l0 80c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-80c0-35.3-28.7-64-64-64l-64.8 0-48 48L448 352c8.8 0 16 7.2 16 16l0 80c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-80c0-8.8 7.2-16 16-16l112.8 0-48-48zM432 408a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"]
  };
  const faShelves = {
    prefix: 'far',
    iconName: 'shelves',
    icon: [640, 512, ["inventory"], "f480", "M24 0C37.3 0 48 10.7 48 24l0 136 544 0 0-136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 160 0 256 0 48c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-24L48 464l0 24c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-48L0 184 0 24C0 10.7 10.7 0 24 0zM48 416l544 0 0-208L48 208l0 208zM352 32c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-64zM128 256l64 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32zm160 0l64 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-64 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32z"]
  };
  const faHouse = {
    prefix: 'far',
    iconName: 'house',
    icon: [576, 512, [127968, 63498, 63500, "home", "home-alt", "home-lg-alt"], "f015", "M303.5 5.7c-9-7.6-22.1-7.6-31.1 0l-264 224c-10.1 8.6-11.3 23.7-2.8 33.8s23.7 11.3 33.8 2.8L64 245.5 64 432c0 44.2 35.8 80 80 80l288 0c44.2 0 80-35.8 80-80l0-186.5 24.5 20.8c10.1 8.6 25.3 7.3 33.8-2.8s7.3-25.3-2.8-33.8l-264-224zM112 432l0-227.2L288 55.5 464 204.8 464 432c0 17.7-14.3 32-32 32l-48 0 0-152c0-22.1-17.9-40-40-40l-112 0c-22.1 0-40 17.9-40 40l0 152-48 0c-17.7 0-32-14.3-32-32zm128 32l0-144 96 0 0 144-96 0z"]
  };
  const faUpload = {
    prefix: 'far',
    iconName: 'upload',
    icon: [512, 512, [], "f093", "M280 360c0 13.3-10.7 24-24 24s-24-10.7-24-24l0-278.1-95 95c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 7c9.4-9.4 24.6-9.4 33.9 0L409 143c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-95-95L280 360zm32-8l0-48 136 0c35.3 0 64 28.7 64 64l0 80c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64l0-80c0-35.3 28.7-64 64-64l136 0 0 48L64 352c-8.8 0-16 7.2-16 16l0 80c0 8.8 7.2 16 16 16l384 0c8.8 0 16-7.2 16-16l0-80c0-8.8-7.2-16-16-16l-136 0zm72 56a24 24 0 1 1 48 0 24 24 0 1 1 -48 0z"]
  };
  const faCompactDisc = {
    prefix: 'far',
    iconName: 'compact-disc',
    icon: [512, 512, [128191, 128192, 128440], "f51f", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-160a96 96 0 1 0 0-192 96 96 0 1 0 0 192zm0-120a24 24 0 1 1 0 48 24 24 0 1 1 0-48zM241.8 128.8c8.8-1 15.1-8.9 14.1-17.7s-8.9-15.1-17.7-14.1C164.1 105.2 105.2 164.1 97 238.2c-1 8.8 5.4 16.7 14.1 17.7s16.7-5.4 17.7-14.1c6.6-59.3 53.7-106.4 113-113z"]
  };
  const faFileArrowDown = {
    prefix: 'far',
    iconName: 'file-arrow-down',
    icon: [384, 512, ["file-download"], "f56d", "M48 448L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zM216 232c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 102.1-31-31c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l72 72c9.4 9.4 24.6 9.4 33.9 0l72-72c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-31 31L216 232z"]
  };
  const faSquareQuestion = {
    prefix: 'far',
    iconName: 'square-question',
    icon: [448, 512, ["question-square"], "f2fd", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm137.8 69.3c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L248 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM192 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"]
  };
  const faAngleDown = {
    prefix: 'far',
    iconName: 'angle-down',
    icon: [448, 512, [8964], "f107", "M241 369c-9.4 9.4-24.6 9.4-33.9 0L47 209c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 369z"]
  };
  const faBookOpenReader = {
    prefix: 'far',
    iconName: 'book-open-reader',
    icon: [512, 512, ["book-reader"], "f5da", "M256 144a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM256 0a96 96 0 1 1 0 192A96 96 0 1 1 256 0zM228 279l-.2-.1c-.7-.5-2.4-1.5-5.2-2.9c-5.5-2.8-15.4-7.3-30.6-12.2c-28-9-74.2-19.3-144-22.7l0 176.3c84.9 5 147.1 22.3 184 35.7L232 282l-4-3zm52 3l0 171.1c36.9-13.4 99.1-30.7 184-35.7l0-176.3c-69.9 3.4-116 13.7-144 22.7c-15.1 4.9-25 9.4-30.6 12.2c-2.8 1.4-4.5 2.4-5.2 2.9l-.2 .1-4 3zM32 192.5C199.2 197.4 256 240 256 240s56.8-42.6 224-47.5c17.7-.5 32 13.9 32 31.5l0 208c0 17.7-14.4 31.9-32 32.7c-114.9 4.7-186.9 33.7-208.8 43.8c-4.8 2.2-9.9 3.5-15.1 3.5s-10.4-1.3-15.1-3.5c-21.9-10.1-93.9-39.1-208.8-43.8c-17.7-.7-32-15-32-32.7L0 224c0-17.7 14.4-32.1 32-31.5z"]
  };
  const faFile = {
    prefix: 'far',
    iconName: 'file',
    icon: [384, 512, [128196, 128459, 61462], "f15b", "M320 464c8.8 0 16-7.2 16-16l0-288-80 0c-17.7 0-32-14.3-32-32l0-80L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l256 0zM0 64C0 28.7 28.7 0 64 0L229.5 0c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3L384 448c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64z"]
  };
  const faArrowDown = {
    prefix: 'far',
    iconName: 'arrow-down',
    icon: [384, 512, [8595], "f063", "M174.6 472.6c4.5 4.7 10.8 7.4 17.4 7.4s12.8-2.7 17.4-7.4l168-176c9.2-9.6 8.8-24.8-.8-33.9s-24.8-8.8-33.9 .8L216 396.1 216 56c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 340.1L41.4 263.4c-9.2-9.6-24.3-9.9-33.9-.8s-9.9 24.3-.8 33.9l168 176z"]
  };
  const faMagnifyingGlass = {
    prefix: 'far',
    iconName: 'magnifying-glass',
    icon: [512, 512, [128269, "search"], "f002", "M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"]
  };
  const faChevronDown = {
    prefix: 'far',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"]
  };
  const faArrowUp = {
    prefix: 'far',
    iconName: 'arrow-up',
    icon: [384, 512, [8593], "f062", "M209.4 39.4C204.8 34.7 198.6 32 192 32s-12.8 2.7-17.4 7.4l-168 176c-9.2 9.6-8.8 24.8 .8 33.9s24.8 8.8 33.9-.8L168 115.9 168 456c0 13.3 10.7 24 24 24s24-10.7 24-24l0-340.1L342.6 248.6c9.2 9.6 24.3 9.9 33.9 .8s9.9-24.3 .8-33.9l-168-176z"]
  };
  const faMegaphone = {
    prefix: 'far',
    iconName: 'megaphone',
    icon: [576, 512, [128227], "f675", "M552 32c-13.3 0-24 10.7-24 24l0 5.7L48 192.6l0-8.6c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 21.7L0 224l0 64 0 18.3L0 328c0 13.3 10.7 24 24 24s24-10.7 24-24l0-8.6 117.6 32.1C162 361.7 160 372.6 160 384c0 53 43 96 96 96c46.9 0 86-33.6 94.3-78.1L528 450.3l0 5.7c0 13.3 10.7 24 24 24s24-10.7 24-24l0-24 0-352 0-24c0-13.3-10.7-24-24-24zM528 400.6L48 269.7l0-27.3L528 111.4l0 289.2zM208 384c0-7.1 1.5-13.8 4.3-19.8l91.5 24.9c-2.6 24.1-23 42.8-47.7 42.8c-26.5 0-48-21.5-48-48z"]
  };
  const faGlobeStand = {
    prefix: 'far',
    iconName: 'globe-stand',
    icon: [448, 512, [], "f5f6", "M15 367c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l18.5-18.5c34.3 27.7 74.9 43.8 116.5 48.3l0 33.3-96 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l240 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-96 0 0-33.3c49.1-5.3 96.8-26.7 134.4-64.3c81.7-81.7 87.1-211 16.1-298.9L401 49c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L332.5 49.6c-9.4 9.4-9.4 24.6 0 33.9c68.7 68.7 68.7 180.2 0 248.9s-180.2 68.7-248.9 0c-9.4-9.4-24.6-9.4-33.9 0L15 367zm97-159a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm240 0A144 144 0 1 0 64 208a144 144 0 1 0 288 0z"]
  };
  const faCopy = {
    prefix: 'far',
    iconName: 'copy',
    icon: [448, 512, [], "f0c5", "M384 336l-192 0c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l140.1 0L400 115.9 400 320c0 8.8-7.2 16-16 16zM192 384l192 0c35.3 0 64-28.7 64-64l0-204.1c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1L192 0c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64L0 448c0 35.3 28.7 64 64 64l192 0c35.3 0 64-28.7 64-64l0-32-48 0 0 32c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256c0-8.8 7.2-16 16-16l32 0 0-48-32 0z"]
  };
  const faPlus = {
    prefix: 'far',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 160L40 232c-13.3 0-24 10.7-24 24s10.7 24 24 24l160 0 0 160c0 13.3 10.7 24 24 24s24-10.7 24-24l0-160 160 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-160 0 0-160z"]
  };
  const faXmark = {
    prefix: 'far',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"]
  };
  const faArrowsUpDownLeftRight = {
    prefix: 'far',
    iconName: 'arrows-up-down-left-right',
    icon: [512, 512, ["arrows"], "f047", "M273 7c-9.4-9.4-24.6-9.4-33.9 0L167 79c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l31-31L232 232 81.9 232l31-31c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L7 239c-9.4 9.4-9.4 24.6 0 33.9l72 72c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-31-31L232 280l0 150.1-31-31c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l72 72c9.4 9.4 24.6 9.4 33.9 0l72-72c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-31 31L280 280l150.1 0-31 31c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l72-72c9.4-9.4 9.4-24.6 0-33.9l-72-72c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l31 31L280 232l0-150.1 31 31c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L273 7z"]
  };
  const faCopyright = {
    prefix: 'far',
    iconName: 'copyright',
    icon: [512, 512, [169], "f1f9", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM199.4 312.6c-31.2-31.2-31.2-81.9 0-113.1s81.9-31.2 113.1 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9c-50-50-131-50-181 0s-50 131 0 181s131 50 181 0c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0c-31.2 31.2-81.9 31.2-113.1 0z"]
  };
  const faMap = {
    prefix: 'far',
    iconName: 'map',
    icon: [576, 512, [128506, 62072], "f279", "M565.6 36.2C572.1 40.7 576 48.1 576 56l0 336c0 10-6.2 18.9-15.5 22.4l-168 64c-5.2 2-10.9 2.1-16.1 .3L192.5 417.5l-160 61c-7.4 2.8-15.7 1.8-22.2-2.7S0 463.9 0 456L0 120c0-10 6.1-18.9 15.5-22.4l168-64c5.2-2 10.9-2.1 16.1-.3L383.5 94.5l160-61c7.4-2.8 15.7-1.8 22.2 2.7zM48 136.5l0 284.6 120-45.7 0-284.6L48 136.5zM360 422.7l0-285.4-144-48 0 285.4 144 48zm48-1.5l120-45.7 0-284.6L408 136.5l0 284.6z"]
  };
  const faPhotoFilm = {
    prefix: 'far',
    iconName: 'photo-film',
    icon: [640, 512, ["photo-video"], "f87c", "M256 48c-8.8 0-16 7.2-16 16l0 224c0 8.7 6.9 15.8 15.6 16l69.1-94.2c4.5-6.2 11.7-9.8 19.4-9.8s14.8 3.6 19.4 9.8L380 232.4l56-85.6c4.4-6.8 12-10.9 20.1-10.9s15.7 4.1 20.1 10.9L578.7 303.8c7.6-1.3 13.3-7.9 13.3-15.8l0-224c0-8.8-7.2-16-16-16L256 48zM192 64c0-35.3 28.7-64 64-64L576 0c35.3 0 64 28.7 64 64l0 224c0 35.3-28.7 64-64 64l-320 0c-35.3 0-64-28.7-64-64l0-224zm-56 64l24 0 0 48 0 88 0 112 0 8 0 80 192 0 0-80 48 0 0 80 48 0c8.8 0 16-7.2 16-16l0-64 48 0 0 64c0 35.3-28.7 64-64 64l-48 0-24 0-24 0-192 0-24 0-24 0-48 0c-35.3 0-64-28.7-64-64L0 192c0-35.3 28.7-64 64-64l48 0 24 0zm-24 48l-48 0c-8.8 0-16 7.2-16 16l0 48 64 0 0-64zm0 288l0-64-64 0 0 48c0 8.8 7.2 16 16 16l48 0zM48 352l64 0 0-64-64 0 0 64zM304 80a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"]
  };
  const faStore = {
    prefix: 'far',
    iconName: 'store',
    icon: [576, 512, [], "f54e", "M507.1 129.5s0 0 0 0c5.8 9.2 6.4 20.5 2.3 30.1c-3.9 9.2-11.1 14.8-20.1 16c-2 .3-3.9 .4-5.8 .4c-11.7 0-22.2-5.1-29.7-13.2c-9.1-10-22-15.7-35.6-15.7s-26.5 5.8-35.5 15.8c-7.3 8.1-17.7 13.2-29.6 13.2c-11.8 0-22.3-5.1-29.6-13.2c-9.1-10.1-22-15.8-35.6-15.8s-26.5 5.7-35.6 15.8c-7.3 8.1-17.7 13.2-29.6 13.2c-11.8 0-22.3-5.1-29.6-13.2c-9.1-10.1-22-15.8-35.6-15.8s-26.5 5.7-35.6 15.8c-7.3 8.1-17.7 13.2-29.6 13.2c-1.8 0-3.8-.1-5.8-.4c-8.9-1.2-16-6.8-19.9-16c-4.1-9.6-3.5-20.9 2.3-30.1c0 0 0 0 0 0s0 0 0 0L120.4 48l335.2 0 51.5 81.5zM483.5 224c4.1 0 8.1-.3 12-.8c55.5-7.4 81.8-72.5 52.1-119.4L490.3 13.1C485.2 5 476.1 0 466.4 0L109.6 0C99.9 0 90.8 5 85.7 13.1L28.3 103.8c-29.6 46.8-3.4 111.9 51.9 119.4c4 .5 8.1 .8 12.1 .8c0 0 0 0 0 0c19.6 0 37.5-6.4 51.9-17c4.8-3.5 9.2-7.6 13.2-11.9c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.6 0 37.5-6.4 52-17c4.8-3.5 9.2-7.6 13.2-12c4 4.4 8.4 8.4 13.2 11.9c14.5 10.6 32.4 17 52 17c19.8 0 37.8-6.5 52.3-17.3c4.7-3.5 9-7.4 12.9-11.7c3.9 4.3 8.3 8.3 13 11.8c14.5 10.7 32.5 17.2 52.2 17.2c0 0 0 0 0 0zM112 336l0-81.6c-6.4 1.1-12.9 1.6-19.6 1.6c-5.5 0-11-.4-16.3-1.1l-.1 0c-4.1-.6-8.1-1.3-12-2.3L64 336l0 48 0 64c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-64 0-48 0-83.4c-4 1-8 1.8-12.3 2.3c0 0 0 0-.1 0c-5.3 .7-10.7 1.1-16.2 1.1c-6.6 0-13.1-.5-19.4-1.6l0 81.6-352 0zm352 48l0 64c0 8.8-7.2 16-16 16l-320 0c-8.8 0-16-7.2-16-16l0-64 352 0z"]
  };
  const faAngleUp = {
    prefix: 'far',
    iconName: 'angle-up',
    icon: [448, 512, [8963], "f106", "M207 143c9.4-9.4 24.6-9.4 33.9 0L401 303c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-143-143L81 337c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L207 143z"]
  };
  const faChevronLeft = {
    prefix: 'far',
    iconName: 'chevron-left',
    icon: [320, 512, [9001], "f053", "M15 239c-9.4 9.4-9.4 24.6 0 33.9L207 465c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L65.9 256 241 81c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L15 239z"]
  };
  const faChevronRight = {
    prefix: 'far',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"]
  };
  const faCloudMusic = {
    prefix: 'far',
    iconName: 'cloud-music',
    icon: [640, 512, [], "f8ae", "M354.9 121.7c13.8 16 36.5 21.1 55.9 12.5c8.9-3.9 18.7-6.2 29.2-6.2c39.8 0 72 32.2 72 72c0 4-.3 7.9-.9 11.7c-3.5 21.6 8.1 42.9 28.1 51.7C570.4 276.9 592 308 592 344c0 46.8-36.6 85.2-82.8 87.8c-.6 0-1.3 .1-1.9 .2l-3.3 0-360 0c-53 0-96-43-96-96c0-41.7 26.6-77.3 64-90.5c19.2-6.8 32-24.9 32-45.3l0-.2s0 0 0 0s0 0 0 0c0-66.3 53.7-120 120-120c36.3 0 68.8 16.1 90.9 41.7zM512 480l0-.2c71.4-4.1 128-63.3 128-135.8c0-55.7-33.5-103.7-81.5-124.7c1-6.3 1.5-12.8 1.5-19.3c0-66.3-53.7-120-120-120c-17.4 0-33.8 3.7-48.7 10.3C360.4 54.6 314.9 32 264 32C171.2 32 96 107.2 96 200l0 .2C40.1 220 0 273.3 0 336c0 79.5 64.5 144 144 144l320 0 40 0 8 0zM416 184c0-5.2-2.6-10.2-6.9-13.2s-9.8-3.7-14.7-1.8l-128 48c-6.2 2.3-10.4 8.3-10.4 15l0 32 0 81.8c-5-1.2-10.4-1.8-16-1.8c-26.5 0-48 14.3-48 32s21.5 32 48 32s48-14.3 48-32l0-100.9 96-36 0 74.7c-5-1.2-10.4-1.8-16-1.8c-26.5 0-48 14.3-48 32s21.5 32 48 32s48-14.3 48-32l0-128 0-32z"]
  };
  const faDog = {
    prefix: 'far',
    iconName: 'dog',
    icon: [576, 512, [128021], "f6d3", "M318 342.2c11.4 9.1 18 22.9 18 37.4l0 68.4c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-173.7L300.5 223c-3.2 .7-6.5 1-9.9 1L144 224l-31.9 0-.1 .8L112 448c0 8.8 7.2 16 16 16l16 0c8.8 0 16-7.2 16-16l0-68.4c0-14.6 6.6-28.3 18-37.4s26.2-12.6 40.4-9.4c9.5 2.1 19.4 3.2 29.6 3.2s20.1-1.1 29.6-3.2c14.2-3.1 29.1 .3 40.4 9.4zM336.9 189l53.4 32.8c7.2-17.7 24.6-29.8 44.4-29.8l45.3 0c26.5 0 48-21.5 48-48l0-32-32 0c-12.7 0-24.9-5.1-33.9-14.1L444.1 80 368 80c-4.2 0-8.4-.6-12.4-1.6L338 183.9c-.3 1.7-.7 3.4-1.1 5.1zM64.2 220.2c-1.1-.3-2.2-.7-3.3-1.1c-27.4-9.6-49-32.4-56.4-61.8L.7 141.8c-3.2-12.9 4.6-25.9 17.5-29.1s25.9 4.6 29.1 17.5c0 0 0 0 0 0l3.9 15.5C55.6 163.5 71.6 176 90 176l54 0 146.7 0L313.5 39.3l.1-.4 .9-5.6 3.1-18.4C319 6.3 326.4 0 335.1 0c5.6 0 10.9 2.6 14.3 7.1l11.2 14.9 3.4 4.6 .2 .3L368 32l76.1 0c12.7 0 24.9 5.1 33.9 14.1L496 64l32 0c26.5 0 48 21.5 48 48l0 32c0 53-43 96-96 96l-45.3 0L432 256l0 192c0 35.3-28.7 64-64 64l-16 0c-35.3 0-64-28.7-64-64l0-19.4 0-48.9c-10.4 2.3-21.1 3.7-32 4.2c-2.7 .1-5.3 .2-8 .2s-5.3-.1-8-.2c-10.9-.5-21.6-1.9-32-4.2l0 48.9 0 19.4c0 35.3-28.7 64-64 64l-16 0c-35.3 0-64-28.7-64-64l0-224c0-1.3 .1-2.6 .2-3.8zM416 112a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  const faFileMusic = {
    prefix: 'far',
    iconName: 'file-music',
    icon: [384, 512, [], "f8b6", "M64 464c-8.8 0-16-7.2-16-16L48 64c0-8.8 7.2-16 16-16l160 0 0 80c0 17.7 14.3 32 32 32l80 0 0 288c0 8.8-7.2 16-16 16L64 464zM64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-293.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0L64 0zM304 208c0-5.2-2.6-10.2-6.9-13.2s-9.8-3.7-14.7-1.8l-128 48c-6.2 2.3-10.4 8.3-10.4 15l0 32 0 81.8c-5-1.2-10.4-1.8-16-1.8c-26.5 0-48 14.3-48 32s21.5 32 48 32s48-14.3 48-32l0-100.9 96-36 0 74.7c-5-1.2-10.4-1.8-16-1.8c-26.5 0-48 14.3-48 32s21.5 32 48 32s48-14.3 48-32l0-128 0-32z"]
  };
  const faUpRightAndDownLeftFromCenter = {
    prefix: 'far',
    iconName: 'up-right-and-down-left-from-center',
    icon: [512, 512, ["expand-alt"], "f424", "M323.3 0C303.8 0 288 15.8 288 35.3c0 9.4 3.7 18.3 10.3 25L326.1 88 264 150.1c-18.7 18.7-18.7 49.1 0 67.9L294.1 248c18.7 18.7 49.1 18.7 67.9 0L424 185.9l27.7 27.7c6.6 6.6 15.6 10.3 25 10.3c19.5 0 35.3-15.8 35.3-35.3L512 40c0-22.1-17.9-40-40-40L323.3 0zM377 71l-23-23L464 48l0 110.1-23-23c-9.4-9.4-24.6-9.4-33.9 0l-79 79L297.9 184l79-79c9.4-9.4 9.4-24.6 0-33.9zM188.7 512c19.5 0 35.3-15.8 35.3-35.3c0-9.4-3.7-18.3-10.3-25L185.9 424 248 361.9c18.7-18.7 18.7-49.1 0-67.9L217.9 264c-18.7-18.7-49.1-18.7-67.9 0L88 326.1 60.3 298.3c-6.6-6.6-15.6-10.3-25-10.3C15.8 288 0 303.8 0 323.3L0 472c0 22.1 17.9 40 40 40l148.7 0zM135 441l23 23L48 464l0-110.1 23 23c9.4 9.4 24.6 9.4 33.9 0l79-79L214.1 328l-79 79c-9.4 9.4-9.4 24.6 0 33.9z"]
  };
  const faCassetteVhs = {
    prefix: 'far',
    iconName: 'cassette-vhs',
    icon: [576, 512, [128252, "vhs"], "f8ec", "M48 416l0-256 480 0 0 256c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16zM64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM352 336l-128 0 0-80 128 0 0 80zM144 296c0-19.4 13.7-35.5 32-39.2l0 78.4c-18.3-3.7-32-19.8-32-39.2zm288 0c0 19.4-13.7 35.5-32 39.2l0-78.4c18.3 3.7 32 19.8 32 39.2zM96 296c0 48.6 39.4 88 88 88l208 0c48.6 0 88-39.4 88-88s-39.4-88-88-88l-208 0c-48.6 0-88 39.4-88 88z"]
  };
  const faBagsShopping = {
    prefix: 'far',
    iconName: 'bags-shopping',
    icon: [576, 512, [128717], "f847", "M176 96c0-26.5 21.5-48 48-48s48 21.5 48 48l0 64-96 0 0-64zm-48 64l-64 0c-35.3 0-64 28.7-64 64L0 416c0 35.3 28.7 64 64 64l96 0 0-48-96 0c-8.8 0-16-7.2-16-16l0-192c0-8.8 7.2-16 16-16l320 0c8.8 0 16 7.2 16 16l48 0c0-35.3-28.7-64-64-64l-64 0 0-64c0-53-43-96-96-96s-96 43-96 96l0 64zm128 96c-35.3 0-64 28.7-64 64l0 128c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-128c0-35.3-28.7-64-64-64l-256 0zm64 80l0 16c0 35.3 28.7 64 64 64s64-28.7 64-64l0-16c0-8.8 7.2-16 16-16s16 7.2 16 16l0 16c0 53-43 96-96 96s-96-43-96-96l0-16c0-8.8 7.2-16 16-16s16 7.2 16 16z"]
  };
  const faSdCard = {
    prefix: 'far',
    iconName: 'sd-card',
    icon: [384, 512, [], "f7c2", "M336 64l0 384c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-293.5c0-4.2 1.7-8.3 4.7-11.3l90.5-90.5c3-3 7.1-4.7 11.3-4.7L320 48c8.8 0 16 7.2 16 16zm48 384l0-384c0-35.3-28.7-64-64-64L154.5 0c-17 0-33.3 6.7-45.3 18.7L18.7 109.3C6.7 121.3 0 137.5 0 154.5L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64zM176 96c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64zm64 0c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64zm64 0c0-8.8-7.2-16-16-16s-16 7.2-16 16l0 64c0 8.8 7.2 16 16 16s16-7.2 16-16l0-64z"]
  };
  const faCirclePlus = {
    prefix: 'far',
    iconName: 'circle-plus',
    icon: [512, 512, ["plus-circle"], "f055", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64 64 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-64 0 0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 64-64 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l64 0 0 64z"]
  };
  const faBook = {
    prefix: 'far',
    iconName: 'book',
    icon: [448, 512, [128212], "f02d", "M0 88C0 39.4 39.4 0 88 0L392 0c30.9 0 56 25.1 56 56l0 288c0 22.3-13.1 41.6-32 50.6l0 69.4 8 0c13.3 0 24 10.7 24 24s-10.7 24-24 24L80 512c-44.2 0-80-35.8-80-80c0-2.7 .1-5.4 .4-8L0 424 0 88zM80 400c-17.7 0-32 14.3-32 32s14.3 32 32 32l288 0 0-64L80 400zM48 358.7c9.8-4.3 20.6-6.7 32-6.7l312 0c4.4 0 8-3.6 8-8l0-288c0-4.4-3.6-8-8-8L88 48C65.9 48 48 65.9 48 88l0 270.7zM152 112l176 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-176 0c-13.3 0-24-10.7-24-24s10.7-24 24-24zm0 80l176 0c13.3 0 24 10.7 24 24s-10.7 24-24 24l-176 0c-13.3 0-24-10.7-24-24s10.7-24 24-24z"]
  };
  const faCheck = {
    prefix: 'far',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M441 103c9.4 9.4 9.4 24.6 0 33.9L177 401c-9.4 9.4-24.6 9.4-33.9 0L7 265c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l119 119L407 103c9.4-9.4 24.6-9.4 33.9 0z"]
  };
  const faImagePortrait = {
    prefix: 'far',
    iconName: 'image-portrait',
    icon: [384, 512, ["portrait"], "f3e0", "M320 48L64 48c-8.8 0-16 7.2-16 16l0 384c0 8.8 7.2 16 16 16l256 0c8.8 0 16-7.2 16-16l0-384c0-8.8-7.2-16-16-16zm0-48c35.3 0 64 28.7 64 64l0 384c0 35.3-28.7 64-64 64L64 512c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L320 0zM128 192a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zM80 356.6c0-37.9 30.7-68.6 68.6-68.6l86.9 0c37.9 0 68.6 30.7 68.6 68.6c0 15.1-12.3 27.4-27.4 27.4l-169.1 0C92.3 384 80 371.7 80 356.6z"]
  };
  const faChevronsRight = {
    prefix: 'far',
    iconName: 'chevrons-right',
    icon: [512, 512, ["chevron-double-right"], "f324", "M465 239c9.4 9.4 9.4 24.6 0 33.9L273 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L239 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L465 239zM81 47L273 239c9.4 9.4 9.4 24.6 0 33.9L81 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L47 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0z"]
  };
  const faBookOpen = {
    prefix: 'far',
    iconName: 'book-open',
    icon: [576, 512, [128214, 128366], "f518", "M156 32C100.6 32 48.8 46.6 27.1 53.6C10.3 59 0 74.5 0 91.1L0 403.5c0 26.1 24 44.2 48 40.2c19.8-3.3 54.8-7.7 100-7.7c54 0 97.5 25.5 112.5 35.6c7.5 5 16.8 8.4 27 8.4c11.5 0 21.6-4.2 29.3-9.9C330.2 460.3 369.1 436 428 436c47.7 0 80.5 4 99 7.2c23.9 4.1 49-13.8 49-40.6l0-311.5c0-16.5-10.3-32.1-27.1-37.5C527.2 46.6 475.4 32 420 32c-36.8 0-71.8 6.4-97.4 12.7c-12.8 3.2-23.5 6.3-30.9 8.7c-1.3 .4-2.6 .8-3.7 1.2c-1.1-.4-2.4-.8-3.7-1.2c-7.5-2.4-18.1-5.5-30.9-8.7C227.8 38.4 192.8 32 156 32zM264 97.3l0 320.6C238 404.2 196.8 388 148 388c-42.9 0-77.4 3.7-100 7.1L48 97.3C70.3 90.6 112.4 80 156 80c31.6 0 62.6 5.6 85.9 11.3c8.6 2.1 16.1 4.2 22.1 6zm48 319.2l0-319.2c6-1.8 13.5-3.9 22.1-6C357.4 85.6 388.4 80 420 80c43.6 0 85.7 10.6 108 17.3l0 297.4c-21.7-3.3-54.9-6.7-100-6.7c-51.4 0-90.8 15-116 28.6z"]
  };
  const faTriangleExclamation = {
    prefix: 'far',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M248.4 84.3c1.6-2.7 4.5-4.3 7.6-4.3s6 1.6 7.6 4.3L461.9 410c1.4 2.3 2.1 4.9 2.1 7.5c0 8-6.5 14.5-14.5 14.5l-387 0c-8 0-14.5-6.5-14.5-14.5c0-2.7 .7-5.3 2.1-7.5L248.4 84.3zm-41-25L9.1 385c-6 9.8-9.1 21-9.1 32.5C0 452 28 480 62.5 480l387 0c34.5 0 62.5-28 62.5-62.5c0-11.5-3.2-22.7-9.1-32.5L304.6 59.3C294.3 42.4 275.9 32 256 32s-38.3 10.4-48.6 27.3zM288 368a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm-8-184c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 96c0 13.3 10.7 24 24 24s24-10.7 24-24l0-96z"]
  };
  const faShare = {
    prefix: 'far',
    iconName: 'share',
    icon: [512, 512, ["mail-forward"], "f064", "M288 240l-96 0c-66.2 0-122 44.7-138.8 105.5C49.9 333.1 48 319.3 48 304c0-70.7 57.3-128 128-128l112 0 24 0c13.3 0 24-10.7 24-24l0-24 0-28.1L456.1 208 336 316.1l0-28.1 0-24c0-13.3-10.7-24-24-24l-24 0zm0 48l0 48 0 16c0 12.6 7.4 24.1 19 29.2s25 3 34.4-5.4l160-144c6.7-6.1 10.6-14.7 10.6-23.8s-3.8-17.7-10.6-23.8l-160-144c-9.4-8.5-22.9-10.6-34.4-5.4s-19 16.6-19 29.2l0 16 0 48-48 0-64 0C78.8 128 0 206.8 0 304c0 78 38.6 126.2 68.7 152.1c4.1 3.5 8.1 6.6 11.7 9.3c3.2 2.4 6.2 4.4 8.9 6.2c4.5 3 8.3 5.1 10.8 6.5c2.5 1.4 5.3 1.9 8.1 1.9c10.9 0 19.7-8.9 19.7-19.7c0-6.8-3.6-13.2-8.3-18.1c-.5-.5-.9-.9-1.4-1.4c-2.4-2.3-5.1-5.1-7.7-8.6c-1.7-2.3-3.4-5-5-7.9c-5.3-9.7-9.5-22.9-9.5-40.2c0-53 43-96 96-96l48 0 48 0z"]
  };
  const faDice = {
    prefix: 'far',
    iconName: 'dice',
    icon: [640, 512, [127922], "f522", "M241 68.3c-9.4-9.4-24.6-9.4-33.9 0L68.3 207c-9.4 9.4-9.4 24.6 0 33.9L207 379.7c9.4 9.4 24.6 9.4 33.9 0L379.7 241c9.4-9.4 9.4-24.6 0-33.9L241 68.3zM173.1 34.3c28.1-28.1 73.7-28.1 101.8 0L413.7 173.1c28.1 28.1 28.1 73.7 0 101.8L274.9 413.7c-28.1 28.1-73.7 28.1-101.8 0L34.3 274.9c-28.1-28.1-28.1-73.7 0-101.8L173.1 34.3zM320 413.8L436.3 297.5c28.6-28.6 37-69.6 25.4-105.5L576 192c35.3 0 64 28.7 64 64l0 192c0 35.3-28.7 64-64 64l-192 0c-35.3 0-64-28.7-64-64l0-34.2zM504 352a24 24 0 1 0 -48 0 24 24 0 1 0 48 0zM120 200a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm104-56a24 24 0 1 1 0-48 24 24 0 1 1 0 48zm0 208a24 24 0 1 1 0-48 24 24 0 1 1 0 48zM328 200a24 24 0 1 1 0 48 24 24 0 1 1 0-48zm-104 0a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"]
  };
  const faBookUser = {
    prefix: 'far',
    iconName: 'book-user',
    icon: [448, 512, [], "f7e7", "M88 0C39.4 0 0 39.4 0 88L0 424l.4 0c-.3 2.6-.4 5.3-.4 8c0 44.2 35.8 80 80 80l344 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-8 0 0-69.4c18.9-9 32-28.3 32-50.6l0-288c0-30.9-25.1-56-56-56L88 0zM368 400l0 64L80 464c-17.7 0-32-14.3-32-32s14.3-32 32-32l288 0zM80 352c-11.4 0-22.2 2.4-32 6.7L48 88c0-22.1 17.9-40 40-40l304 0c4.4 0 8 3.6 8 8l0 288c0 4.4-3.6 8-8 8l-40 0 0-16c0-44.2-35.8-80-80-80l-64 0c-44.2 0-80 35.8-80 80l0 16-48 0zM240 224a64 64 0 1 0 0-128 64 64 0 1 0 0 128z"]
  };
  const faCircleXmark = {
    prefix: 'far',
    iconName: 'circle-xmark',
    icon: [512, 512, [61532, "times-circle", "xmark-circle"], "f057", "M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 0z"]
  };
  const faNoteSticky = {
    prefix: 'far',
    iconName: 'note-sticky',
    icon: [448, 512, [62026, "sticky-note"], "f249", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l224 0 0-80c0-17.7 14.3-32 32-32l80 0 0-224c0-8.8-7.2-16-16-16L64 80zM288 480L64 480c-35.3 0-64-28.7-64-64L0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 224 0 5.5c0 17-6.7 33.3-18.7 45.3l-90.5 90.5c-12 12-28.3 18.7-45.3 18.7l-5.5 0z"]
  };
  const faPrint = {
    prefix: 'far',
    iconName: 'print',
    icon: [512, 512, [128424, 128438, 9113], "f02f", "M112 160l0-96c0-8.8 7.2-16 16-16l229.5 0c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c3 3 4.7 7.1 4.7 11.3l0 69.5 48 0 0-69.5c0-17-6.7-33.3-18.7-45.3L402.7 18.7C390.7 6.7 374.5 0 357.5 0L128 0C92.7 0 64 28.7 64 64l0 96 48 0zm16 208l256 0 0 96-256 0 0-96zm-16-48c-17.7 0-32 14.3-32 32l-32 0 0-96c0-8.8 7.2-16 16-16l384 0c8.8 0 16 7.2 16 16l0 96-32 0c0-17.7-14.3-32-32-32l-288 0zm320 80l48 0c17.7 0 32-14.3 32-32l0-112c0-35.3-28.7-64-64-64L64 192c-35.3 0-64 28.7-64 64L0 368c0 17.7 14.3 32 32 32l48 0 0 80c0 17.7 14.3 32 32 32l288 0c17.7 0 32-14.3 32-32l0-80z"]
  };
  const faUmbrella = {
    prefix: 'far',
    iconName: 'umbrella',
    icon: [576, 512, [], "f0e9", "M288 0c13.3 0 24 10.7 24 24l0 24.9c123.7 9.6 226.1 93 255.5 204.4c.4 1.4 .7 2.9 1.1 4.3c1.4 5.8 2.7 11.7 3.7 17.6c.6 3.5 1.1 7.1 1.6 10.7c2 15.6-17.3 24.4-27.8 12.7c-2.5-2.8-5.5-5.5-8.8-8c-3.4-2.5-7.1-4.9-11.1-7l-.7-.4-.6-.3c-13.5-6.8-29.7-11-44.9-11c-30.1 0-56.3 16.6-70 41.2c-.5 .9-1 1.7-1.4 2.6c-2.1 4.1-3.8 8.3-5.1 12.8c-.7 2.4-1.4 4.9-1.9 7.5c-1.7 8.7-8.7 15.9-17.6 15.9s-15.8-7.2-17.6-15.9c-.5-2.5-1.1-5-1.9-7.5c-1.4-4.4-3.1-8.7-5.1-12.8c-.5-.9-.9-1.8-1.4-2.6c-13.7-24.6-39.9-41.2-70-41.2s-56.3 16.6-70 41.2c-.5 .9-.9 1.7-1.4 2.6c-2.1 4.1-3.8 8.3-5.1 12.8c-.7 2.4-1.4 4.9-1.9 7.5c-1.7 8.7-8.7 15.9-17.6 15.9s-15.8-7.2-17.6-15.9c-.5-2.5-1.1-5-1.9-7.5c-1.4-4.4-3.1-8.7-5.1-12.8c-.5-.9-.9-1.8-1.4-2.6C152.3 288.6 126.1 272 96 272c-15.2 0-31.4 4.2-44.9 11c-.4 .2-.9 .5-1.3 .7c-4 2.1-7.7 4.4-11.1 7c-3.3 2.5-6.3 5.2-8.8 8C19.4 310.4 .1 301.5 2.1 285.9c.5-3.6 1-7.2 1.6-10.7c1-5.9 2.3-11.8 3.7-17.6c.4-1.4 .7-2.9 1.1-4.3C37.9 141.9 140.3 58.6 264 48.9L264 24c0-13.3 10.7-24 24-24zm96 267.3c23.4-26.6 57.7-43.3 96-43.3c8.6 0 17.5 .9 26.4 2.6C468.9 150.2 386.1 96 288 96s-180.9 54.2-218.4 130.6c8.9-1.7 17.8-2.6 26.4-2.6c38.3 0 72.6 16.8 96 43.3c23.4-26.6 57.7-43.3 96-43.3s72.6 16.8 96 43.3zM288 304c8.7 0 16.9 2.3 24 6.4l0 128.1c0 40.6-32.9 73.4-73.4 73.4c-27.8 0-53.2-15.7-65.7-40.6l-2.3-4.7c-5.9-11.9-1.1-26.3 10.7-32.2s26.3-1.1 32.2 10.7l2.3 4.7c4.3 8.6 13.1 14.1 22.8 14.1c14.1 0 25.4-11.4 25.4-25.4l0-128.1c7.1-4.1 15.3-6.4 24-6.4z"]
  };
  const faSquareShareNodes = {
    prefix: 'far',
    iconName: 'square-share-nodes',
    icon: [448, 512, ["share-alt-square"], "f1e1", "M64 80c-8.8 0-16 7.2-16 16l0 320c0 8.8 7.2 16 16 16l320 0c8.8 0 16-7.2 16-16l0-320c0-8.8-7.2-16-16-16L64 80zM0 96C0 60.7 28.7 32 64 32l320 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zm352 80c0 26.5-21.5 48-48 48c-9.8 0-18.9-2.9-26.5-7.9L197.7 256l79.9 39.9c7.6-5 16.7-7.9 26.5-7.9c26.5 0 48 21.5 48 48s-21.5 48-48 48c-25.5 0-46.4-20-47.9-45.1l-85.6-42.8c-7.6 5-16.7 7.9-26.5 7.9c-26.5 0-48-21.5-48-48s21.5-48 48-48c9.8 0 18.9 2.9 26.5 7.9l85.6-42.8C257.6 148 278.5 128 304 128c26.5 0 48 21.5 48 48z"]
  };
  const faVideo = {
    prefix: 'far',
    iconName: 'video',
    icon: [576, 512, ["video-camera"], "f03d", "M64 112c-8.8 0-16 7.2-16 16l0 256c0 8.8 7.2 16 16 16l256 0c8.8 0 16-7.2 16-16l0-256c0-8.8-7.2-16-16-16L64 112zM0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 33 0 190 0 33c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM528 363.4l0-214.7L416 199l0-52.6L520.3 99.5c5.1-2.3 10.6-3.5 16.2-3.5c21.8 0 39.5 17.7 39.5 39.5l0 241c0 21.8-17.7 39.5-39.5 39.5c-5.6 0-11.1-1.2-16.2-3.5L416 365.6l0-52.6 112 50.4z"]
  };

  exports.faAd = faAd;
  exports.faAddressBook = faAddressBook;
  exports.faAddressCard = faAddressCard;
  exports.faAngleDown = faAngleDown;
  exports.faAngleUp = faAngleUp;
  exports.faAnglesDown = faAnglesDown;
  exports.faAnglesUp = faAnglesUp;
  exports.faArrowDown = faArrowDown;
  exports.faArrowRight = faArrowRight;
  exports.faArrowUp = faArrowUp;
  exports.faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  exports.faArrowsUpDownLeftRight = faArrowsUpDownLeftRight;
  exports.faBackwardFast = faBackwardFast;
  exports.faBagShopping = faBagShopping;
  exports.faBagsShopping = faBagsShopping;
  exports.faBallot = faBallot;
  exports.faBan = faBan;
  exports.faBook = faBook;
  exports.faBookAtlas = faBookAtlas;
  exports.faBookHeart = faBookHeart;
  exports.faBookOpen = faBookOpen;
  exports.faBookOpenReader = faBookOpenReader;
  exports.faBookSparkles = faBookSparkles;
  exports.faBookUser = faBookUser;
  exports.faBookmark = faBookmark;
  exports.faBooks = faBooks;
  exports.faBooksMedical = faBooksMedical;
  exports.faBoxCheck = faBoxCheck;
  exports.faBoxTaped = faBoxTaped;
  exports.faCalendarDays = faCalendarDays;
  exports.faCaretDown = faCaretDown;
  exports.faCaretLeft = faCaretLeft;
  exports.faCaretRight = faCaretRight;
  exports.faCaretUp = faCaretUp;
  exports.faCartShopping = faCartShopping;
  exports.faCassetteBetamax = faCassetteBetamax;
  exports.faCassetteTape = faCassetteTape;
  exports.faCassetteVhs = faCassetteVhs;
  exports.faChartLine = faChartLine;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronLeft = faChevronLeft;
  exports.faChevronRight = faChevronRight;
  exports.faChevronUp = faChevronUp;
  exports.faChevronsLeft = faChevronsLeft;
  exports.faChevronsRight = faChevronsRight;
  exports.faChild = faChild;
  exports.faCircleArrowRight = faCircleArrowRight;
  exports.faCircleCheck = faCircleCheck;
  exports.faCircleExclamation = faCircleExclamation;
  exports.faCircleInfo = faCircleInfo;
  exports.faCircleNotch = faCircleNotch;
  exports.faCirclePlay = faCirclePlay;
  exports.faCirclePlus = faCirclePlus;
  exports.faCircleQuestion = faCircleQuestion;
  exports.faCircleSmall = faCircleSmall;
  exports.faCircleXmark = faCircleXmark;
  exports.faCloudMusic = faCloudMusic;
  exports.faCompactDisc = faCompactDisc;
  exports.faCopy = faCopy;
  exports.faCopyright = faCopyright;
  exports.faCreditCardFront = faCreditCardFront;
  exports.faCrown = faCrown;
  exports.faDice = faDice;
  exports.faDiceThree = faDiceThree;
  exports.faDog = faDog;
  exports.faDownload = faDownload;
  exports.faEnvelope = faEnvelope;
  exports.faEnvelopesBulk = faEnvelopesBulk;
  exports.faEye = faEye;
  exports.faFile = faFile;
  exports.faFileArrowDown = faFileArrowDown;
  exports.faFileAudio = faFileAudio;
  exports.faFileCertificate = faFileCertificate;
  exports.faFileExcel = faFileExcel;
  exports.faFileImage = faFileImage;
  exports.faFileLines = faFileLines;
  exports.faFileMusic = faFileMusic;
  exports.faFileWord = faFileWord;
  exports.faFilm = faFilm;
  exports.faFilmCanister = faFilmCanister;
  exports.faFilter = faFilter;
  exports.faFloppyDisk = faFloppyDisk;
  exports.faFolderOpen = faFolderOpen;
  exports.faForwardFast = faForwardFast;
  exports.faGamepadModern = faGamepadModern;
  exports.faGear = faGear;
  exports.faGlobeStand = faGlobeStand;
  exports.faHeadphones = faHeadphones;
  exports.faHighlighter = faHighlighter;
  exports.faHouse = faHouse;
  exports.faImagePortrait = faImagePortrait;
  exports.faImages = faImages;
  exports.faLayerGroup = faLayerGroup;
  exports.faLock = faLock;
  exports.faMagnifyingGlass = faMagnifyingGlass;
  exports.faMap = faMap;
  exports.faMapLocation = faMapLocation;
  exports.faMegaphone = faMegaphone;
  exports.faMessageLines = faMessageLines;
  exports.faMp3Player = faMp3Player;
  exports.faMug = faMug;
  exports.faNewspaper = faNewspaper;
  exports.faNoteSticky = faNoteSticky;
  exports.faPen = faPen;
  exports.faPenFancy = faPenFancy;
  exports.faPhotoFilm = faPhotoFilm;
  exports.faPlus = faPlus;
  exports.faPresentationScreen = faPresentationScreen;
  exports.faPrint = faPrint;
  exports.faPuzzlePiece = faPuzzlePiece;
  exports.faRectangleList = faRectangleList;
  exports.faSdCard = faSdCard;
  exports.faShapes = faShapes;
  exports.faShare = faShare;
  exports.faShareNodes = faShareNodes;
  exports.faShelves = faShelves;
  exports.faShirt = faShirt;
  exports.faSquare = faSquare;
  exports.faSquareCheck = faSquareCheck;
  exports.faSquareQuestion = faSquareQuestion;
  exports.faSquareShareNodes = faSquareShareNodes;
  exports.faStar = faStar;
  exports.faStore = faStore;
  exports.faTableColumns = faTableColumns;
  exports.faTabletScreenButton = faTabletScreenButton;
  exports.faTag = faTag;
  exports.faTrashCan = faTrashCan;
  exports.faTriangleExclamation = faTriangleExclamation;
  exports.faUmbrella = faUmbrella;
  exports.faUpRightAndDownLeftFromCenter = faUpRightAndDownLeftFromCenter;
  exports.faUpRightFromSquare = faUpRightFromSquare;
  exports.faUpload = faUpload;
  exports.faUsbDrive = faUsbDrive;
  exports.faUser = faUser;
  exports.faUserGear = faUserGear;
  exports.faUserGroupCrown = faUserGroupCrown;
  exports.faUserSlash = faUserSlash;
  exports.faUsers = faUsers;
  exports.faVideo = faVideo;
  exports.faVolumeHigh = faVolumeHigh;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
