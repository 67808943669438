define("ember-window-mock/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports._setCurrentHandler = void 0;
  let exportedWindow;
  let _setCurrentHandler = _exports._setCurrentHandler = void 0;
  if (false /* DEBUG */) {
    // this Proxy handler will be used to preserve the unaltered behavior of the window global by default
    const doNothingHandler = {
      get(target, prop) {
        const value = Reflect.get(target, prop);

        // make sure the function receives the original window as the this context! (e.g. alert will throw an invalid invocation error)
        if (typeof value === 'function') {
          return new Proxy(value, {
            apply(t, _thisArg, argumentsList) {
              return Reflect.apply(value, target, argumentsList);
            }
          });
        }
        return value;
      },
      set: Reflect.set,
      has: Reflect.has,
      deleteProperty: Reflect.deleteProperty,
      getOwnPropertyDescriptor: Reflect.getOwnPropertyDescriptor,
      defineProperty: Reflect.defineProperty
    };
    let currentHandler = doNothingHandler;

    // private function to replace the default handler in tests
    _exports._setCurrentHandler = _setCurrentHandler = function () {
      let handler = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : doNothingHandler;
      return currentHandler = handler;
    };
    const proxyHandler = {
      get() {
        return currentHandler.get(...arguments);
      },
      set() {
        return currentHandler.set(...arguments);
      },
      has() {
        return currentHandler.has(...arguments);
      },
      deleteProperty() {
        return currentHandler.deleteProperty(...arguments);
      },
      getOwnPropertyDescriptor() {
        return currentHandler.getOwnPropertyDescriptor(...arguments);
      },
      defineProperty() {
        return currentHandler.defineProperty(...arguments);
      }
    };
    exportedWindow = new Proxy(window, proxyHandler);
  } else {
    exportedWindow = window;
  }
  var _default = _exports.default = exportedWindow;
});