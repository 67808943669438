define("@ember-data/legacy-compat/-private", ["exports", "@ember-data/legacy-compat/-private-87269113"], function (_exports, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "FetchManager", {
    enumerable: true,
    get: function () {
      return _private.F;
    }
  });
  Object.defineProperty(_exports, "SaveOp", {
    enumerable: true,
    get: function () {
      return _private.S;
    }
  });
  Object.defineProperty(_exports, "Snapshot", {
    enumerable: true,
    get: function () {
      return _private.c;
    }
  });
  Object.defineProperty(_exports, "SnapshotRecordArray", {
    enumerable: true,
    get: function () {
      return _private.b;
    }
  });
});