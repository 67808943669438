define("ember-set-body-class/services/body-class", ["exports", "@ember/application", "@ember/service", "@ember/array", "@ember/runloop"], function (_exports, _application, _service, _array, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class BodyClassService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_dom", (0, _application.getOwner)(this).lookup('service:-document'));
      _defineProperty(this, "_fastboot", (0, _application.getOwner)(this).lookup('service:fastboot'));
      _defineProperty(this, "registrations", new Map());
    }
    register(id, classNames) {
      this.registrations.set(id, classNames);
      this.scheduleUpdate();
    }
    deregister(id) {
      this.registrations.delete(id);
      this.scheduleUpdate();
    }
    get names() {
      let allNames = new Set();
      for (let classNames of this.registrations.values()) {
        for (let className of classNames) {
          allNames.add(className);
        }
      }
      return [...allNames];
    }
    scheduleUpdate() {
      this.scheduledRun = (0, _runloop.once)(this, this.updateBodyClass);
    }
    updateBodyClass() {
      if (!this._dom) {
        return;
      }
      let registeredClassNames = this.names;
      let body = this._dom.body;
      let attr = body.getAttribute('class');
      let classList = (0, _array.A)(attr ? attr.split(/\s+/) : []);
      classList.removeObjects(this._previousNames || []);
      classList.addObjects(registeredClassNames);
      this._previousNames = registeredClassNames;
      body.setAttribute('class', classList.join(' '));
    }
    willDestroy() {
      if (this._fastboot && this._fastboot.isFastBoot) {
        // prevent FastBoot from removing the CSS classes
        // again before the response is sent out
        (0, _runloop.cancel)(this.scheduledRun);
      }
    }
  }
  _exports.default = BodyClassService;
});